import { AutoMap } from '@automapper/classes';
import { IsString } from 'class-validator';
import { CreateOrganizationDto } from './create-organization.dto';
import { ClassToPlainISODateFormatNoMillisUTCTimezone } from '@bookabl/shared/util';

export class OrganizationDto extends CreateOrganizationDto {
  @AutoMap()
  @IsString()
  id!: string;

  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  createdAt!: Date;

  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  updatedAt!: Date;

  constructor(props?: OrganizationDto) {
    super(props);
    if (!props) return;

    this.id = props.id;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}

export const emptyOrganization = (): OrganizationDto =>
  new OrganizationDto({
    id: '',
    name: '',
    publicId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  });
