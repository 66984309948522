import { AutoMap } from '@automapper/classes';
import {
  IsNotEmpty,
  IsNumberString,
  IsOptional,
  IsString,
} from 'class-validator';

export class CreateUserRegistrationProfileDto {
  @IsString()
  @AutoMap()
  userId!: string;

  @IsNumberString({ no_symbols: true })
  @AutoMap()
  regProfileId!: string;

  @IsOptional()
  @IsNotEmpty()
  @AutoMap()
  profile?: { [key: string]: unknown };

  constructor(props?: CreateUserRegistrationProfileDto) {
    if (!props) return;
    this.userId = props.userId;
    this.regProfileId = props.regProfileId;
    this.profile = props.profile;
  }
}
