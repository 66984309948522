import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

export type EditEventState =
  | 'EventSelection'
  | 'GeneralInfo'
  | 'Details'
  | 'Payment'
  | 'Publish';

interface CurrentEventContextProps {
  isCreate: boolean;
  pageState: EditEventState;
  setPageState: Dispatch<EditEventState>;
}

const CurrentEventContext = createContext<CurrentEventContextProps>({
  isCreate: true,
  pageState: 'EventSelection',
} as CurrentEventContextProps);

export function CurrentEventProvider({ children }: { children: ReactNode }) {
  const { id } = useParams() as { id: string };
  const [pageState, setPageState] = useState<EditEventState>('EventSelection');

  const stateMemo = useMemo<CurrentEventContextProps>(() => {
    return {
      isCreate: id === 'new',
      pageState,
      setPageState,
    };
  }, [id, pageState]);

  return (
    <CurrentEventContext.Provider value={stateMemo}>
      {children}
    </CurrentEventContext.Provider>
  );
}

export default function useCurrentEvent(): CurrentEventContextProps {
  return useContext(CurrentEventContext);
}
