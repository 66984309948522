import {
  As,
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
} from '@chakra-ui/react';
import useAdminSession from '../../../context/admin-session-context';
import { ReactNode, useEffect, useState } from 'react';
import { MeService } from '@bookabl/client/api-client';
import { ProgramUserDto } from '@bookabl/shared/model/identity';
import { Link, To } from 'react-router-dom';
import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import { programRoute } from '../../../utils/routing-utils';
import {
  FiArrowDownRight,
  FiArrowUpRight,
  FiCalendar,
  FiPlus,
  FiStar,
  FiUser,
} from 'react-icons/fi';

interface StatProps {
  icon: As;
  label: string;
  value: string;
  delta?: {
    value: string;
    isUpwardsTrend: boolean;
  };
}
export const Stat = (props: StatProps) => {
  const { label, value, icon, delta, ...boxProps } = props;
  return (
    <Box
      px={{ base: '4', md: '6' }}
      py={{ base: '5', md: '6' }}
      bg="bg-surface"
      borderRadius="lg"
      boxShadow={'sm'}
      {...boxProps}
    >
      <Stack spacing={{ base: '5', md: '6' }}>
        <Stack direction="row" justify="space-between">
          <HStack spacing="4">
            <Square size="12" bg="bg-accent-subtle" borderRadius="md">
              <Icon as={icon} boxSize="6" color="on-accent" />
            </Square>
            <Text fontWeight="medium">{label}</Text>
          </HStack>
          {/*<Icon as={FiMoreVertical} boxSize="5" color="muted" />*/}
        </Stack>
        <Stack spacing="4">
          <Heading size={{ base: 'sm', md: 'md' }}>{value}</Heading>
          {delta && (
            <HStack spacing="1" fontWeight="medium">
              <Icon
                color={delta.isUpwardsTrend ? 'success' : 'error'}
                as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight}
                boxSize="5"
              />
              <Text color={delta.isUpwardsTrend ? 'success' : 'error'}>
                {delta.value}
              </Text>
              <Text color="muted">vs last week</Text>
            </HStack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export interface ProgramCardProps {
  name: string;
  description?: string;
  logo: ReactNode;
  to: To;
}

function ProgramCard({ logo, name, description, to }: ProgramCardProps) {
  return (
    <Link to={to}>
      <Box
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        h={224}
      >
        {logo}
        <Box p="6">
          <Box fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
            {name}
          </Box>
          {description && (
            <Box fontSize="sm" lineHeight="tight" noOfLines={1}>
              {description}
            </Box>
          )}
        </Box>
      </Box>
    </Link>
  );
}

export function OrganizationPage() {
  const { currentOrganization } = useAdminSession();
  const [programs, setPrograms] = useState<ProgramUserDto[]>([]);
  useEffect(() => {
    if (currentOrganization.id) {
      MeService.getProgramsForCurrentUser(currentOrganization.id).then(
        (programAccess) => setPrograms(programAccess.programs)
      );
    }
  }, [currentOrganization]);

  return (
    <AdminPageLayout
      title="All programs dashboard"
      description="Manage all of your programs"
      headerContent={
        <SimpleGrid columns={3} spacing={10} my={5}>
          <Stat
            icon={FiStar}
            label="Total programs"
            value={`${programs.length}`}
          />
          <Stat icon={FiCalendar} label="Total events (all-time)" value="22" />
          <Stat
            icon={FiUser}
            label="Total registrants (all-time)"
            value="175"
          />
        </SimpleGrid>
      }
    >
      <Flex mb={4} justifyContent="end">
        <Button
          leftIcon={<FiPlus />}
          to={`new-program`}
          as={Link}
          colorScheme="blue"
        >
          New Program
        </Button>
      </Flex>
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3 }}
        spacing={{ base: 5, md: 10 }}
      >
        {programs.map((program) => (
          <ProgramCard
            name={program.program.name}
            description={program.program.description}
            logo={
              <Flex justifyContent="center" p={4} bg="gray.50">
                <Avatar size="xl" name={program.program.name} />
              </Flex>
            }
            to={programRoute(program.program.orgId, program.program.id)}
          />
        ))}
      </SimpleGrid>
    </AdminPageLayout>
  );
}

export default OrganizationPage;
