export enum OrganizationRoleDto {
  OrgOwner = 'OrgOwner',
  OrgAdmin = 'OrgAdmin',
  OrgMember = 'OrgMember',
  OrgProgramAccess = 'OrgProgramAccess',
}

export function toOrganizationRoleDtoLabel(dto: OrganizationRoleDto) {
  switch (dto) {
    case OrganizationRoleDto.OrgOwner:
      return 'Owner';
    case OrganizationRoleDto.OrgAdmin:
      return 'Admin';
    case OrganizationRoleDto.OrgMember:
      return 'Member';
    case OrganizationRoleDto.OrgProgramAccess:
      return 'Program Access';
    default:
      return `${dto}`;
  }
}
