import EventSelectionPage from './components/event-selection-page';
import { EventTypeDto } from '@bookabl/shared/model/event';
import EventGeneralInfoSection from './components/event-general-info-section';
import EventPaymentSection from './components/event-payment-section';
import useCurrentProgram from '../../../../context/current-program-context';
import { useGetStripeIntegrationsForProgram } from '@bookabl/client/api-client';
import EventDetailsSection from './components/event-details-section';
import EventPublishSection from './components/event-publish-section';
import useCurrentEvent from '../../context/edit-event-context';
import { useNavigate } from 'react-router-dom';

export function EditEventPage() {
  const { isCreate, pageState, setPageState } = useCurrentEvent();
  const navigate = useNavigate();

  const onEventSelection = (eventType: EventTypeDto) => {
    console.log(eventType);
    setPageState('GeneralInfo');
  };

  const onEventGeneralSection = () => {
    setPageState('Details');
  };

  const onEventDetailSection = () => {
    setPageState('Payment');
  };

  const onEventPaymentSection = () => {
    setPageState('Publish');
  };

  const onSave = () => {
    navigate('./..', { replace: true });
  };

  const { currentProgram } = useCurrentProgram();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: payments } = useGetStripeIntegrationsForProgram(
    currentProgram.orgId,
    currentProgram.id
  );

  switch (pageState) {
    case 'EventSelection':
      return <EventSelectionPage onEventSelection={onEventSelection} />;
    case 'GeneralInfo':
      return (
        <EventGeneralInfoSection
          isCreate={isCreate}
          onNext={onEventGeneralSection}
        />
      );
    case 'Details':
      return (
        <EventDetailsSection
          isCreate={isCreate}
          onNext={onEventDetailSection}
        />
      );
    case 'Payment':
      return (
        <EventPaymentSection
          isCreate={isCreate}
          onNext={onEventPaymentSection}
        />
      );
    case 'Publish':
      return <EventPublishSection onSave={onSave} />;
  }
}

export default EditEventPage;
