import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CredentialDto } from '@bookabl/shared/model/authentication';
import { AuthenticationService, MeService } from '@bookabl/client/api-client';
import { logger } from '@bookabl/client/util';
import { UserAccessScopeDto } from '@bookabl/shared/model/identity';
import { useLocation, useNavigate } from 'react-router-dom';

export interface CurrentUserState {
  name: string;
  id: string;
  hasAdminAccess: boolean;
}

interface AuthenticationContextProps {
  isAuthenticated: boolean;
  currentUser?: CurrentUserState;
  authenticate: (credentials: CredentialDto) => void;
  logout: (returnToLogin?: boolean) => void;
  navigateToHome: () => void;
}

const AuthenticationContext = createContext<AuthenticationContextProps>({
  isAuthenticated: false,
} as AuthenticationContextProps);

export function AuthenticationProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [currentUser, setCurrentUser] = useState<CurrentUserState>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isInitialLoadFinished, setIsInitialLoadFinished] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadCurrentUser().finally(() => setIsInitialLoadFinished(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isAdminLocation = location.pathname.startsWith('/admin');
    const hasAdminAccess = currentUser?.hasAdminAccess || false;

    const loadAdminNoAccess = isAdminLocation && !hasAdminAccess;
    const loadNonAdminWithAdminAccess = !isAdminLocation && hasAdminAccess;
    const shouldGoToCorrectHome =
      loadAdminNoAccess || loadNonAdminWithAdminAccess;

    if (isAuthenticated && isInitialLoadFinished && shouldGoToCorrectHome) {
      navigateToHome();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoadFinished, currentUser]);

  function navigateToHome() {
    const hasAdminAccess = currentUser?.hasAdminAccess || false;

    navigate(hasAdminAccess ? '/admin' : '/home', { replace: true });
  }

  function loadCurrentUser() {
    return MeService.getCurrentUser()
      .then((currentUser) => {
        const hasAdminAccess =
          currentUser.accessScopes?.includes(UserAccessScopeDto.Organization) ||
          false;
        updateCurrentUser({
          id: currentUser.user.id,
          name: currentUser.user.name,
          hasAdminAccess,
        });
      })
      .catch(() => {
        // ignore
      });
  }

  function authenticate(credentials: CredentialDto) {
    return AuthenticationService.authenticate(credentials).then(() =>
      loadCurrentUser()
    );
  }

  function logout(returnToLogin = true) {
    logger.debug('Logging out');
    return AuthenticationService.logout().then(() => {
      updateCurrentUser(undefined);
      if (returnToLogin) {
        navigate('/login', { replace: true });
        return;
      }

      return;
    });
  }

  function updateCurrentUser(user?: CurrentUserState) {
    setCurrentUser(user);
    setIsAuthenticated(!!user);
  }

  const stateMemo = useMemo(
    () => ({
      isAuthenticated,
      currentUser,
      authenticate,
      logout,
      navigateToHome,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, isAuthenticated]
  );

  return (
    <AuthenticationContext.Provider value={stateMemo}>
      {isInitialLoadFinished && children}
    </AuthenticationContext.Provider>
  );
}

export function useAuth(): AuthenticationContextProps {
  return useContext(AuthenticationContext);
}

export default useAuth;
