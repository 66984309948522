/**
 * Format phone number to E.164 format
 * @param phone phone number string
 */
export function toE164Format(phone: string) {
  return phone.startsWith('+') ? phone : `+${phone}`;
}

/**
 * Type guard for checking if an error is really an error.
 *
 * @param err error object
 * @returns true if error, false otherwise
 */
export function isError(err: unknown): err is Error {
  return err instanceof Error;
}
