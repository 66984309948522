import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { FiEdit, FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { organizationRoute, programRoute } from '../../../utils/routing-utils';
import { StripeIntegrationDtoPage } from '@bookabl/client/api-client';

export interface StripeIntegrationPageProps {
  integrations?: StripeIntegrationDtoPage;
  context: 'ORGANIZATION' | 'PROGRAM';
}

export function StripeIntegrationPage({
  integrations,
  context,
}: StripeIntegrationPageProps) {
  return (
    <AdminPageLayout
      title="Stripe"
      description="Stripe is a suite of APIs powering online payment processing and commerce solutions for internet businesses of all sizes. Accept payments and scale faster."
      isLoading={!integrations}
    >
      <Flex mb={5} justifyContent="end">
        <Button leftIcon={<FiPlus />} colorScheme="blue" as={Link} to="./new">
          Add Stripe account
        </Button>
      </Flex>
      <Box
        boxShadow={{ base: 'none', md: 'sm' }}
        borderRadius={{ base: 'none', md: 'lg' }}
      >
        <Table>
          <Thead>
            <Tr>
              <Th borderTopWidth="0">Label</Th>
              <Th borderTopWidth="0">Status</Th>
              <Th borderTopWidth="0"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {integrations?.data.map((i) => {
              return (
                <Tr key={i.id}>
                  <Td>{i.label}</Td>
                  <Td>
                    {i.enabled ? (
                      <Badge colorScheme="green">Enabled</Badge>
                    ) : (
                      <Badge>Disabled</Badge>
                    )}
                  </Td>
                  <Td display="flex" justifyContent="end">
                    <HStack>
                      {context === 'PROGRAM' && !i.programId ? (
                        <Tooltip
                          label="Integration managed by the organization."
                          placement="left"
                          shouldWrapChildren
                          hasArrow
                        >
                          <IconButton
                            aria-label="Edit stripe integration"
                            icon={<FiEdit />}
                            variant="ghost"
                            disabled
                          />
                        </Tooltip>
                      ) : (
                        <IconButton
                          aria-label="Edit stripe integration"
                          icon={<FiEdit />}
                          variant="ghost"
                          as={Link}
                          to={
                            context === 'ORGANIZATION'
                              ? organizationRoute(
                                  i.orgId,
                                  `/integrations/stripe/${i.id}`
                                )
                              : programRoute(
                                  i.orgId,
                                  i.programId || '',
                                  `/integrations/stripe/${i.id}`
                                )
                          }
                        />
                      )}
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </AdminPageLayout>
  );
}

export default StripeIntegrationPage;
