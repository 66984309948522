import { useQuery } from 'react-query';
import { SponsorClient, SponsorDtoPage } from './sponsor-client';
import { SponsorDto } from '@bookabl/shared/model/content';

export function useGetSponsorsForProgram(
  orgId: string,
  programId: string,
  published?: boolean
) {
  return useQuery<SponsorDtoPage>(
    ['useGetSponsorsForProgram', orgId, programId, published],
    () => SponsorClient.getSponsorsForProgram(orgId, programId, published)
  );
}

export function useGetSponsorForProgram(
  orgId: string,
  programId: string,
  id: string,
  enabled = true
) {
  return useQuery<SponsorDto>(
    ['useGetSponsorForProgram', orgId, programId, id],
    () => SponsorClient.getSponsorForProgram(orgId, programId, id),
    { enabled }
  );
}
