import { Type } from 'class-transformer';
import { AutoMap } from '@automapper/classes';
import {
  OrganizationDto,
  OrganizationRoleDto,
} from '@bookabl/shared/model/organization';

export class UserOrganizationDto {
  @AutoMap({ typeFn: () => String })
  role!: OrganizationRoleDto;

  @AutoMap({ typeFn: () => OrganizationDto })
  @Type(() => OrganizationDto)
  organization!: OrganizationDto;
}
