import { useQuery } from 'react-query';
import {
  OrganizationClient,
  OrganizationUserDtoPage,
} from './organization-client';
import { OrganizationUserDto } from '@bookabl/shared/model/organization';

export const useGetOrganizationUsersQueryKey = 'useGetOrganizationUsers';
export function useGetOrganizationUsers(orgId: string) {
  return useQuery<OrganizationUserDtoPage>(
    [useGetOrganizationUsersQueryKey, orgId],
    () => OrganizationClient.getOrganizationUsers(orgId),
    { enabled: !!orgId }
  );
}

export function useGetOrganizationUser(
  orgId: string,
  id: string,
  disabled?: boolean
) {
  return useQuery<OrganizationUserDto>(
    ['useGetOrganizationUser', orgId, id],
    () => OrganizationClient.getOrganizationUser(orgId, id),
    { enabled: !disabled }
  );
}
