import { AutoMap } from '@automapper/classes';
import { ProgramUserDto } from './program-user.dto';
import { Type } from 'class-transformer';

export class UserProgramAccessDto {
  @AutoMap()
  userId!: string;

  @AutoMap({ typeFn: () => ProgramUserDto })
  @Type(() => ProgramUserDto)
  programs!: ProgramUserDto[];
}
