import { getTimeZones, TimeZone } from '@vvo/tzdb';

function isTimeZoneValid(tz: string): boolean {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    throw 'Timezones are not available in this environment';
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (err) {
    return false;
  }
}

const validTimeZones = getTimeZones()
  .sort((tz1, tz2) => {
    if (tz1.countryCode === 'US' && tz2.countryCode === 'US') {
      return tz1.name.localeCompare(tz2.name);
    } else if (tz1.countryCode === 'US' && tz2.countryCode !== 'US') {
      return -1;
    } else if (tz1.countryCode !== 'US' && tz2.countryCode === 'US') {
      return 1;
    } else {
      return tz1.name.localeCompare(tz2.name);
    }
  })
  .filter((tz) => isTimeZoneValid(tz.name));

export const timeZones = validTimeZones;

export function guessTimeZone(): TimeZone | undefined {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    // throw 'Timezones are not available in this environment';
    return;
  }

  return validTimeZones.find(
    (tz) => tz.name === Intl.DateTimeFormat().resolvedOptions().timeZone
  );
}
