import { useQuery } from 'react-query';
import { EventHostDtoPage, HostClient } from './host-client';
import { EventHostDto } from '@bookabl/shared/model/event';

export function useGetHostsForProgram(
  orgId: string,
  programId: string,
  published?: boolean
) {
  return useQuery<EventHostDtoPage>(
    ['useGetHostsForProgram', orgId, programId, published],
    () => HostClient.getHostsForProgram(orgId, programId, published)
  );
}

export function useGetHostForProgram(
  orgId: string,
  programId: string,
  id: string,
  enabled = true
) {
  return useQuery<EventHostDto>(
    ['useGetHostForProgram', orgId, programId, id],
    () => HostClient.getHostForProgram(orgId, programId, id),
    { enabled }
  );
}
