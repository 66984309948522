import { plainToClass } from 'class-transformer';
import { apiClient } from './api-client';
import { CreateProgramDto, ProgramDto } from '@bookabl/shared/model/program';

export const ProgramClient = {
  async getProgram(programId: string) {
    return apiClient
      .get<ProgramDto>(`/programs/${programId}`)
      .then((res) => plainToClass(ProgramDto, res.data));
  },

  async createProgram(dto: CreateProgramDto): Promise<ProgramDto> {
    return apiClient
      .post<ProgramDto>(`/programs`, dto)
      .then((res) => plainToClass(ProgramDto, res.data));
  },

  async setProgramMedia(id: string, mediaUrl: string): Promise<ProgramDto> {
    return apiClient
      .post<ProgramDto>(`/programs/${id}/media`, {
        mediaUrl,
      })
      .then((res) => plainToClass(ProgramDto, res.data));
  },
};
