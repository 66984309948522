import { AutoMap } from '@automapper/classes';
import { IsBoolean, IsString } from 'class-validator';

export class UpdateStripeIntegrationDto {
  @AutoMap()
  @IsString()
  label!: string;

  @AutoMap()
  @IsBoolean()
  enabled!: boolean;

  constructor(props?: UpdateStripeIntegrationDto) {
    Object.assign(this, props || {});
  }
}
