/* eslint-disable-next-line */
import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import useCurrentProgram from '../../../context/current-program-context';
import { useGetSponsorsForProgram } from '@bookabl/client/api-client';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { FiEdit, FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { organizationRoute, programRoute } from '../../../utils/routing-utils';
import { imageUrl } from '../../../utils/utils';
import { PageContext } from '../../../model/page-context';

export interface SponsorPageProps {
  pageContext: PageContext;
}

export function SponsorPage({ pageContext }: SponsorPageProps) {
  const { currentProgram } = useCurrentProgram();
  const { data: sponsors } = useGetSponsorsForProgram(
    currentProgram.orgId,
    currentProgram.id
  );
  return (
    <AdminPageLayout
      title="Sponsors"
      description="Manage your program sponsors. Published sponsors can be shown on your program and events."
      isLoading={!sponsors}
    >
      <Flex mb={5} justifyContent="end">
        <Button leftIcon={<FiPlus />} colorScheme="blue" as={Link} to="./new">
          Add Sponsor
        </Button>
      </Flex>
      <Box
        boxShadow={{ base: 'none', md: 'sm' }}
        borderRadius={{ base: 'none', md: 'lg' }}
      >
        <Table>
          <Thead>
            <Tr>
              <Th borderTopWidth={0}>Name</Th>
              <Th borderTopWidth={0}>Status</Th>
              <Th borderTopWidth={0}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {sponsors?.data.map((sponsor) => {
              return (
                <Tr key={sponsor.id}>
                  <Td>
                    <HStack>
                      <Avatar
                        bg={sponsor.imageUrl ? 'transparent' : undefined}
                        name={sponsor.name}
                        src={
                          sponsor.imageUrl
                            ? imageUrl(sponsor.imageUrl)
                            : undefined
                        }
                      />
                      <Text>{sponsor.name}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {sponsor.published ? (
                      <Badge colorScheme="green">Published</Badge>
                    ) : (
                      <Badge>Unpublished</Badge>
                    )}
                  </Td>
                  <Td textAlign="end">
                    {pageContext === PageContext.Program &&
                    !sponsor.programId ? (
                      <Tooltip
                        label="Integration managed by the organization."
                        placement="left"
                        shouldWrapChildren
                        hasArrow
                      >
                        <IconButton
                          aria-label="Edit stripe integration"
                          icon={<FiEdit />}
                          variant="ghost"
                          disabled
                        />
                      </Tooltip>
                    ) : (
                      <IconButton
                        aria-label="Edit stripe integration"
                        icon={<FiEdit />}
                        variant="ghost"
                        as={Link}
                        to={
                          pageContext === PageContext.Organization
                            ? organizationRoute(
                                sponsor.orgId,
                                `/sponsors/${sponsor.id}`
                              )
                            : programRoute(
                                sponsor.orgId,
                                sponsor.programId || '',
                                `/sponsors/${sponsor.id}`
                              )
                        }
                      />
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </AdminPageLayout>
  );
}

export default SponsorPage;
