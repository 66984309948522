import { useQuery, UseQueryResult } from 'react-query';
import { MeService } from '../me.service';
import {
  UserOrganizationAccessDto,
  UserProgramAccessDto,
} from '@bookabl/shared/model/identity';

export function useMeGetOrganizationsForCurrentUser() {
  return useQuery<UserOrganizationAccessDto>(
    ['meGetOrganizationsForCurrentUser'],
    () => MeService.getOrganizationsForCurrentUser()
  );
}

export function useMeGetProgramsForCurrentUser(
  orgId: string
): UseQueryResult<UserProgramAccessDto> {
  return useQuery<UserProgramAccessDto>(['meGetProgramsFormCurrentUser'], () =>
    MeService.getProgramsForCurrentUser(orgId)
  );
}
