import {
  Alert,
  AlertIcon,
  Box,
  Button,
  chakra,
  CircularProgress,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { organizationRoute, programRoute } from '../../../utils/routing-utils';
import useAdminSession from '../../../context/admin-session-context';
import { FiTrash2 } from 'react-icons/fi';
import { environment } from '../../../../environments/environment';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  IntegrationClient,
  useFindStripeIntegrationById,
} from '@bookabl/client/api-client';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import {
  AuthorizeStripeDto,
  UpdateStripeIntegrationDto,
} from '@bookabl/shared/model/integration';
import { logger } from '@bookabl/client/util';
import { DATE_FORMAT_MM_DD_YYYY } from '../../../utils/date-utils';
import { format } from 'date-fns';
import useCurrentProgram from '../../../context/current-program-context';

const STRIPE_AUTHORIZATION_URL = `${environment.stripeOauthUrl}?response_type=code&scope=read_write&client_id=${environment.stripeClientId}&redirect_uri=${environment.appBaseUrl}`;

interface EditStripeIntegrationForm {
  label: string;
  enabled: boolean;
}

const createFormSchema = yup.object({
  label: yup.string().required('Label is required'),
});

type PageState = 'edit' | 'install' | 'connect';

interface EditStripeIntegrationPageProps {
  context: 'PROGRAM' | 'ORGANIZATION';
}

export default function EditStripeIntegrationPage({
  context,
}: EditStripeIntegrationPageProps) {
  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm<EditStripeIntegrationForm>({
    resolver: yupResolver(createFormSchema),
    mode: 'onBlur',
  });
  const { id } = useParams() as { id: string };
  const { state } = useLocation();
  const { code, action } = (state || {}) as {
    code?: string;
    action?: 'install';
  };

  const isCreate = id === 'new';
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentOrganization } = useAdminSession();
  const { currentProgram } = useCurrentProgram();
  const [actionState, setActionState] = useState<PageState>(action || 'edit');
  const { data: integration, isLoading } = useFindStripeIntegrationById(
    currentOrganization.id,
    currentProgram.id,
    id,
    isCreate
  );
  const authorizeStripe = useMutation((dto: AuthorizeStripeDto) =>
    context === 'ORGANIZATION'
      ? IntegrationClient.authorizeStripeIntegrationForOrganization(
          currentOrganization.id,
          id,
          dto
        )
      : IntegrationClient.authorizeStripeIntegrationForProgram(
          currentProgram.orgId,
          currentProgram.id,
          id,
          dto
        )
  );

  const deleteStripe = useMutation(() =>
    context === 'ORGANIZATION'
      ? IntegrationClient.deleteStripeIntegration(currentOrganization.id, id)
      : IntegrationClient.deleteStripeIntegrationForProgram(
          currentProgram.orgId,
          currentProgram.id,
          id
        )
  );

  const updateStripe = useMutation((dto: UpdateStripeIntegrationDto) =>
    context === 'ORGANIZATION'
      ? IntegrationClient.updateStripeIntegrationForOrganization(
          currentOrganization.id,
          id,
          dto
        )
      : IntegrationClient.updateStripeIntegrationForProgram(
          currentProgram.orgId,
          currentProgram.id,
          id,
          dto
        )
  );

  useEffect(() => {
    if (!integration) {
      return;
    }

    if (!isDirty) {
      reset({ label: integration.label, enabled: integration.enabled });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integration, isDirty]);

  useEffect(() => {
    if (integration) {
      if (actionState === 'install' && !!code) {
        authorizeStripe.mutate({ code });
      } else if (actionState !== 'connect' && !integration.installationDate) {
        setActionState('connect');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionState, integration, code]);

  useEffect(() => {
    if (authorizeStripe.isError) {
      toast({
        description: 'There was a problem installing the integration',
        status: 'error',
        isClosable: true,
      });
      setActionState('edit');
    } else if (authorizeStripe.isSuccess) {
      toast({
        description: 'Integration installed successfully',
        status: 'success',
      });
      setActionState('edit');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizeStripe.isError, authorizeStripe.isSuccess]);

  useEffect(() => {
    if (deleteStripe.isError) {
      toast({
        status: 'error',
        description: 'There was an error removing the integration',
      });
      logger.error(`Error removing team member`, deleteStripe.error);
    } else if (deleteStripe.isSuccess) {
      navigate(
        context === 'ORGANIZATION'
          ? organizationRoute(currentOrganization.id, '/integrations/stripe')
          : programRoute(
              currentProgram.orgId,
              currentProgram.id,
              '/integrations/stripe'
            )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStripe.isError, deleteStripe.isSuccess]);

  useEffect(() => {
    if (updateStripe.isError) {
      toast({
        status: 'error',
        description: 'There was an error updating the integration',
      });
      logger.error(`Error updating stripe integration`, updateStripe.error);
    } else if (updateStripe.isSuccess) {
      toast({
        status: 'success',
        description: 'Changes saved successfully',
      });

      navigate(
        context === 'ORGANIZATION'
          ? organizationRoute(currentOrganization.id, '/integrations/stripe')
          : programRoute(
              currentProgram.orgId,
              currentProgram.id,
              '/integrations/stripe'
            )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStripe.isError, updateStripe.isSuccess]);

  const onSubmit: SubmitHandler<EditStripeIntegrationForm> = async (data) => {
    if (isCreate) {
      const created =
        context === 'ORGANIZATION'
          ? await IntegrationClient.createStripeIntegrationForOrganization(
              currentOrganization.id,
              data
            )
          : await IntegrationClient.createStripeIntegrationForProgram(
              currentProgram.orgId,
              currentProgram.id,
              data
            );
      let replacementUrl = `${STRIPE_AUTHORIZATION_URL}/admin/oauth/install/stripe&state=o~${currentOrganization.id},i~${created.id}`;
      if (context === 'PROGRAM') {
        replacementUrl = `${STRIPE_AUTHORIZATION_URL}/admin/oauth/install/stripe&state=o~${currentProgram.orgId},p~${currentProgram.id},i~${created.id}`;
      }
      window.location.replace(replacementUrl);
    } else if (actionState === 'connect') {
      let replacementUrl = `${STRIPE_AUTHORIZATION_URL}/admin/oauth/install/stripe&state=o~${currentOrganization.id},i~${id}`;
      if (context === 'PROGRAM') {
        replacementUrl = `${STRIPE_AUTHORIZATION_URL}/admin/oauth/install/stripe&state=o~${currentProgram.orgId},p~${currentProgram.id},i~${id}`;
      }

      window.location.replace(replacementUrl);
    } else {
      updateStripe.mutate(data);
    }
  };

  const confirmDelete = async () => {
    deleteStripe.mutate();
  };

  return (
    <AdminPageLayout
      title="Manage Stripe integration"
      description="Connect and modify your Stripe integration."
      isLoading={isLoading}
    >
      {integration && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Are you sure?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to remove the Stripe integration "
              {integration.label}"?
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  variant="ghost"
                  onClick={onClose}
                  isDisabled={deleteStripe.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={confirmDelete}
                  isLoading={deleteStripe.isLoading}
                >
                  Yes, remove
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {actionState === 'install' ? (
        <HStack>
          <CircularProgress isIndeterminate />
          <Text fontWeight="semibold">Installing...</Text>
        </HStack>
      ) : (
        <chakra.form h="full" onSubmit={handleSubmit(onSubmit)}>
          <Flex flexDir="column" h="full">
            <Flex
              flexDir="column"
              h="full"
              flex="1"
              justifyContent="space-between"
            >
              <VStack alignItems="start">
                {actionState === 'connect' && !isCreate && (
                  <Alert status="warning" variant="left-accent" mb="5">
                    <AlertIcon />
                    You need to finish connecting your account to Stripe.
                  </Alert>
                )}
                <FormControl isInvalid={!!errors.label}>
                  <FormLabel>Label</FormLabel>
                  <Input {...register('label')} />
                  <FormErrorMessage>
                    {errors.label && errors.label.message}
                  </FormErrorMessage>
                </FormControl>
                {actionState !== 'connect' && !isCreate && (
                  <>
                    <FormControl isInvalid={!!errors.enabled}>
                      <HStack>
                        <Switch
                          size="lg"
                          {...register('enabled')}
                          isChecked={getValues('enabled')}
                        />
                        <FormLabel>
                          {getValues('enabled') ? 'Enabled' : 'Disabled'}
                        </FormLabel>
                      </HStack>

                      <FormErrorMessage>
                        {errors.enabled && errors.enabled.message}
                      </FormErrorMessage>
                    </FormControl>
                    {integration?.installationDate && (
                      <Text>{`Installed on ${format(
                        integration?.installationDate,
                        DATE_FORMAT_MM_DD_YYYY
                      )}`}</Text>
                    )}
                  </>
                )}
              </VStack>

              <HStack
                borderTop="1px solid"
                borderColor="gray.200"
                justifyContent="space-between"
                pt={6}
              >
                <Box>
                  {!isCreate && (
                    <Button
                      colorScheme="red"
                      leftIcon={<FiTrash2 />}
                      isLoading={deleteStripe.isLoading}
                      onClick={onOpen}
                    >
                      Delete
                    </Button>
                  )}
                </Box>
                <HStack spacing={4}>
                  <Button
                    variant="outline"
                    as={Link}
                    to={
                      context === 'ORGANIZATION'
                        ? organizationRoute(
                            currentOrganization.id,
                            '/integrations/stripe'
                          )
                        : programRoute(
                            currentOrganization.id,
                            currentProgram.id,
                            '/integrations/stripe'
                          )
                    }
                    isLoading={updateStripe.isLoading || deleteStripe.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isLoading={updateStripe.isLoading || deleteStripe.isLoading}
                  >
                    {actionState === 'connect' || isCreate
                      ? 'Connect to Stripe'
                      : 'Save Changes'}
                  </Button>
                </HStack>
              </HStack>
            </Flex>
          </Flex>
        </chakra.form>
      )}
    </AdminPageLayout>
  );
}
