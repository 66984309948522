import { Box, Image, SimpleGrid, Text } from '@chakra-ui/react';
import AdminPageLayout from '../../../../../components/shared/layouts/admin-page-layout';
import { EventTypeDto } from '@bookabl/shared/model/event';

export interface EventSelectionPageProps {
  onEventSelection: (selection: EventTypeDto) => void;
}

export function EventSelectionPage({
  onEventSelection,
}: EventSelectionPageProps) {
  const onSelection = (selection: EventTypeDto) => {
    onEventSelection(selection);
  };

  return (
    <AdminPageLayout title="What type of event are you creating?">
      <SimpleGrid columns={3} spacing={6}>
        <Box
          maxW="sm"
          h={340}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          _hover={{ cursor: 'pointer' }}
          onClick={() => onSelection('Single')}
        >
          <Image
            w="sm"
            maxH="180px"
            objectFit="cover"
            src="/assets/images/dumbbells-fitness.svg"
          />
          <Box p={3}>
            <Text fontSize="2xl" fontWeight="semibold">
              Single Event
            </Text>
            <Text>Use this event type when having a basic event.</Text>
          </Box>
        </Box>
      </SimpleGrid>
    </AdminPageLayout>
  );
}

export default EventSelectionPage;
