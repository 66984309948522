import { AutoMap } from '@automapper/classes';
import {
  IsBoolean,
  IsEnum,
  IsOptional,
  IsString,
  MaxLength,
} from 'class-validator';
import type { EventTypeDto } from './event-type.dto';
import { EventLocationTypeDto } from './event-location-type.dto';
import { AccountType } from '@bookabl/shared/model/authentication';

export class EventDto {
  @AutoMap()
  @IsString()
  id!: string;

  @AutoMap()
  @IsString()
  orgId!: string;

  @AutoMap()
  @IsString()
  programId!: string;

  @AutoMap()
  @IsString()
  name!: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  @MaxLength(500)
  summary?: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  slug?: string;

  @AutoMap({ typeFn: () => String })
  type!: EventTypeDto;

  @IsBoolean()
  @AutoMap()
  published!: boolean;

  @IsEnum(AccountType)
  @AutoMap({ typeFn: () => String })
  locationType!: EventLocationTypeDto;

  constructor();
  constructor(props?: EventDto) {
    Object.assign(this, props || {});
  }
}
