import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import useCurrentProgram from '../../../context/current-program-context';
import { useGetHostsForProgram } from '@bookabl/client/api-client';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { FiEdit, FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { programRoute } from '../../../utils/routing-utils';
import { imageUrl } from '../../../utils/utils';
import { PageContext } from '../../../model/page-context';

export interface SponsorPageProps {
  pageContext: PageContext.Program;
}

export function HostPage({ pageContext }: SponsorPageProps) {
  const { currentProgram } = useCurrentProgram();
  const { data: hosts } = useGetHostsForProgram(
    currentProgram.orgId,
    currentProgram.id
  );

  return (
    <AdminPageLayout
      title="Host"
      description="Manage your program's event host. Event hosts are the host, instructors, teachers, etc. that host your events."
      isLoading={!hosts}
    >
      <Flex mb={5} justifyContent="end">
        <Button leftIcon={<FiPlus />} colorScheme="blue" as={Link} to="./new">
          Add Host
        </Button>
      </Flex>
      <Box
        boxShadow={{ base: 'none', md: 'sm' }}
        borderRadius={{ base: 'none', md: 'lg' }}
      >
        <Table>
          <Thead>
            <Tr>
              <Th borderTopWidth={0}>Name</Th>
              <Th borderTopWidth={0}>Status</Th>
              <Th borderTopWidth={0}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {hosts?.data.map((host) => {
              return (
                <Tr key={host.id}>
                  <Td>
                    <HStack>
                      <Avatar
                        bg={host.profileImageUrl ? 'transparent' : undefined}
                        name={host.name}
                        src={
                          host.profileImageUrl
                            ? imageUrl(host.profileImageUrl)
                            : undefined
                        }
                      />
                      <Text>{host.name}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    {host.published ? (
                      <Badge colorScheme="green">Published</Badge>
                    ) : (
                      <Badge>Unpublished</Badge>
                    )}
                  </Td>
                  <Td textAlign="end">
                    {pageContext === PageContext.Program && !host.programId ? (
                      <Tooltip
                        label="Integration managed by the organization."
                        placement="left"
                        shouldWrapChildren
                        hasArrow
                      >
                        <IconButton
                          aria-label="Edit stripe integration"
                          icon={<FiEdit />}
                          variant="ghost"
                          disabled
                        />
                      </Tooltip>
                    ) : (
                      <IconButton
                        aria-label="Edit event host"
                        icon={<FiEdit />}
                        variant="ghost"
                        as={Link}
                        to={programRoute(
                          host.orgId,
                          host.programId || '',
                          `/hosts/${host.id}`
                        )}
                      />
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </AdminPageLayout>
  );
}

export default HostPage;
