import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Select,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from '@chakra-ui/react';
import useCurrentProgram from '../../../../../context/current-program-context';
import { FiMap, FiSearch } from 'react-icons/fi';
import { useEffect, useRef, useState } from 'react';
import { guessTimeZone, timeZones } from '@bookabl/client/util';
import { environment } from '../../../../../../environments/environment';
import {
  FieldErrors,
  SubmitHandler,
  useForm,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import startOfHour from 'date-fns/startOfHour';
import addHours from 'date-fns/addHours';
import parse from 'date-fns/parse';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

import { DATE_FORMAT_FULL_DATE_TIME } from '../../../../../utils/date-utils';
import {
  EventDateTimeTypeDto,
  EventLocationTypeDto,
} from '@bookabl/shared/model/event';
import { DatePickerInput } from './event-general-info-section/date-picker-input';
import { TimePickerInput } from './event-general-info-section/time-picker-input';

interface EventGeneralInfo {
  name: string;
  summary?: string;
  dateTimeType: EventDateTimeTypeDto;
  startDateTime: Date;
  endDateTime: Date;
  slug?: string;
  timeZone: string;
  showDurationInMins: boolean;
  showEndDate: boolean;
  locationType: string;
  inPersonLocation?: string;
  onlineStreamType?: string;
  onlineStreamUrl?: string;
}
interface EventGeneralInfoForm extends EventGeneralInfo {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

function ComboBox({
  location,
  setValue,
  errors,
}: {
  location?: string;
  setValue: UseFormSetValue<EventGeneralInfoForm>;
  errors: FieldErrors<EventGeneralInfoForm>;
}) {
  const ref = useRef<HTMLInputElement>(null);
  const autocomplete = useRef<google.maps.places.Autocomplete | null>(null);
  useEffect(() => {
    if (ref.current) {
      if (!autocomplete.current) {
        if (location) {
          ref.current.value = location;
        }

        autocomplete.current = new google.maps.places.Autocomplete(
          ref.current,
          {
            componentRestrictions: { country: 'us' },
            fields: ['formatted_address'],
            strictBounds: false,
          }
        );

        autocomplete.current.addListener('place_changed', () => {
          const address = autocomplete.current?.getPlace().formatted_address;
          if (ref.current && address) {
            ref.current.value = address;
            setValue('inPersonLocation', address, { shouldValidate: true });
          }
        });
      }
    }
  }, [ref, autocomplete, setValue]);

  return (
    <FormControl isInvalid={!!errors.inPersonLocation}>
      <FormLabel>In person location</FormLabel>
      <InputGroup>
        <InputLeftElement>
          <FiSearch />
        </InputLeftElement>
        <Input
          ref={ref}
          onChange={(v) =>
            setValue('inPersonLocation', v.target.value, {
              shouldValidate: true,
            })
          }
        />
      </InputGroup>
      <FormErrorMessage>{errors.inPersonLocation?.message}</FormErrorMessage>
    </FormControl>
  );
}

function convertTimeToString({
  hours,
  minutes,
}: {
  hours: number;
  minutes: number;
}) {
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

interface OnlineMeetingProps {
  initialStreamType?: string;
  register: UseFormRegister<EventGeneralInfoForm>;
  errors: FieldErrors<EventGeneralInfoForm>;
}

function OnlineEventLocation({
  initialStreamType,
  register,
  errors,
}: OnlineMeetingProps) {
  const [streamType, setStreamType] = useState<string>(
    initialStreamType || 'manual'
  );
  return (
    <Stack spacing={5}>
      <FormControl isInvalid={!!errors.onlineStreamType}>
        <FormLabel>Stream type</FormLabel>
        <Select
          {...register('onlineStreamType')}
          onChange={(e) => setStreamType(e.target.value)}
        >
          <option value="manual">Manual URL</option>
          <option value="zoom">Zoom Meeting</option>
        </Select>
        <FormErrorMessage>{errors.onlineStreamType?.message}</FormErrorMessage>
      </FormControl>
      {streamType === 'manual' && (
        <FormControl isInvalid={!!errors.onlineStreamUrl}>
          <FormLabel>URL</FormLabel>
          <Input {...register('onlineStreamUrl')} />
          <FormErrorMessage>{errors.onlineStreamUrl?.message}</FormErrorMessage>
        </FormControl>
      )}
    </Stack>
  );
}

interface InPersonEventLocationProps {
  setValue: UseFormSetValue<EventGeneralInfoForm>;
  errors: FieldErrors<EventGeneralInfoForm>;
  location?: string;
}

function InPersonEventLocation({
  setValue,
  location,
  errors,
}: InPersonEventLocationProps) {
  return (
    <Box>
      <ComboBox location={location} setValue={setValue} errors={errors} />
      <Flex
        mt={5}
        overflow="hidden"
        w="full"
        h={300}
        bg="gray.50"
        justifyContent="center"
        alignItems="center"
      >
        {!location && <Icon as={FiMap} boxSize={32} color="gray.500" />}
        {location && (
          <iframe
            key={location}
            width="100%"
            height="300"
            style={{ border: 0 }}
            loading="lazy"
            allowFullScreen
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${
              environment.googleApiKey
            }
    &q=${encodeURIComponent(location || '')}`}
          ></iframe>
        )}
      </Flex>
    </Box>
  );
}

const formSchema = yup.object({
  name: yup.string().required('Event name is required'),
  summary: yup.string().max(500, 'Must be less than 500 characters'),
  slug: yup
    .string()
    .trim()
    .matches(
      /^[a-zA-Z0-9_-]+$/,
      'Event URL can only be letters, numbers, dash ( - ), or underscore ( _ )'
    )
    .min(3, 'Must be at least 3 characters long'),
  dateTimeType: yup.string().required(),
  startDateTime: yup.date(),
  endDateTime: yup
    .date()
    .when(
      'startDateTime',
      (s, schema) =>
        s && schema.min(s, 'Event end cannot be before the event start')
    ),
  timeZone: yup
    .string()
    .when('dateTimeType', {
      is: EventDateTimeTypeDto.TBD,
      then: yup.string().required('Time zone is required'),
    })
    .required('Time zone is required'),
  locationType: yup.string().required(),
  inPersonLocation: yup.string().when('locationType', (locationType) => {
    if (
      locationType === EventLocationTypeDto.InPerson ||
      locationType === EventLocationTypeDto.Hybrid
    ) {
      return yup.string().required('In person location is required');
    }

    return yup.string().optional();
  }),
  onlineStreamType: yup.string().when('locationType', {
    is: EventLocationTypeDto.Online,
    then: yup.string().required('Stream type is required'),
  }),
  onlineStreamUrl: yup.string().when('onlineStreamType', {
    is: 'manual',
    then: yup
      .string()
      .url('URL format is required')
      .required('URL is required'),
  }),
});

const eventTypes = [
  EventLocationTypeDto.InPerson,
  EventLocationTypeDto.Online,
  EventLocationTypeDto.Hybrid,
  EventLocationTypeDto.TBD,
];

const dateTimeTypes = [EventDateTimeTypeDto.Single, EventDateTimeTypeDto.TBD];

export interface EventGeneralInfoSectionProps {
  isCreate: boolean;
  onNext: (data: EventGeneralInfo) => void;
}

export function EventGeneralInfoSection({
  isCreate,
  onNext,
}: EventGeneralInfoSectionProps) {
  const { currentProgram } = useCurrentProgram();
  const now = new Date();
  const startDate = startOfHour(now);
  const endDate = addHours(startDate, 1);

  const [locationActiveTab, setLocationActiveTab] = useState<number>(0);
  const [dateTimeActiveTab, setDateTimeActiveTab] = useState<number>(0);
  const [startDateDisplay, setStartDateDisplay] = useState(
    formatInTimeZone(
      startDate,
      guessTimeZone()?.name || '',
      DATE_FORMAT_FULL_DATE_TIME
    )
  );
  const [endDateDisplay, setEndDateDisplay] = useState(
    formatInTimeZone(
      endDate,
      guessTimeZone()?.name || '',
      DATE_FORMAT_FULL_DATE_TIME
    )
  );
  const [durationDisplay, setDurationDisplay] = useState(
    `${differenceInMinutes(endDate, startDate)} mins`
  );

  const {
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<EventGeneralInfoForm>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      startDate: formatInTimeZone(
        startDate,
        guessTimeZone()?.name || '',
        'yyyy-MM-dd'
      ),
      startTime: `${convertTimeToString({
        hours: startDate.getHours(),
        minutes: startDate.getMinutes(),
      })}`,
      endDate: formatInTimeZone(
        endDate,
        guessTimeZone()?.name || '',
        'yyyy-MM-dd'
      ),
      endTime: `${convertTimeToString({
        hours: endDate.getHours(),
        minutes: endDate.getMinutes(),
      })}`,
      timeZone: guessTimeZone()?.name,
      startDateTime: startDate,
      endDateTime: endDate,
      dateTimeType: EventDateTimeTypeDto.Single,
      locationType: EventLocationTypeDto.InPerson,
    },
  });

  const [
    watchedStartDate,
    watchedStartTime,
    watchedEndDate,
    watchedEndTime,
    watchedTimeZone,
  ] = watch(['startDate', 'startTime', 'endDate', 'endTime', 'timeZone']);
  const [watchedShowDurationInMins, watchedShowEndDate] = watch([
    'showDurationInMins',
    'showEndDate',
  ]);

  const [inPersonLocation, onlineStreamType] = watch([
    'inPersonLocation',
    'onlineStreamType',
  ]);

  useEffect(() => {
    const startDateTimeLocal = parse(
      `${watchedStartDate} ${watchedStartTime}`,
      'yyyy-MM-dd HH:mm',
      new Date()
    );
    const endDateTimeLocal = parse(
      `${watchedEndDate} ${watchedEndTime}`,
      'yyyy-MM-dd HH:mm',
      new Date()
    );

    const startDateTimeUtc = zonedTimeToUtc(
      startDateTimeLocal,
      watchedTimeZone
    );

    const endDateTimeUtc = zonedTimeToUtc(endDateTimeLocal, watchedTimeZone);
    setStartDateDisplay(
      formatInTimeZone(
        startDateTimeUtc,
        guessTimeZone()?.name || watchedTimeZone,
        DATE_FORMAT_FULL_DATE_TIME
      )
    );
    setEndDateDisplay(
      formatInTimeZone(
        endDateTimeUtc,
        guessTimeZone()?.name || watchedTimeZone,
        DATE_FORMAT_FULL_DATE_TIME
      )
    );
    setDurationDisplay(
      `${differenceInMinutes(endDateTimeUtc, startDateTimeUtc)} mins`
    );

    setValue('startDateTime', startDateTimeUtc, { shouldValidate: true });
    setValue('endDateTime', endDateTimeUtc, { shouldValidate: true });
  }, [
    watchedStartDate,
    watchedStartTime,
    watchedEndDate,
    watchedEndTime,
    watchedTimeZone,
  ]);

  const onSubmit: SubmitHandler<EventGeneralInfoForm> = async (data) => {
    const { startDate, startTime, endDate, endTime, ...rest } = data;
    onNext(rest);
  };

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDir="column" h="full">
        <Flex flexDir="column" flex="1" h="full" justifyContent="space-between">
          <Stack direction="row" spacing={10}>
            <Stack spacing={10} w="container.md">
              <Box>
                <Text fontSize="3xl">General Info</Text>
                <Text mb={4}>Name and setup your event</Text>
                <Box rounded="lg" bg="bg-surface" p={5}>
                  <Stack spacing={5}>
                    <FormControl isInvalid={!!errors.name}>
                      <FormLabel>Event name</FormLabel>
                      <Input {...register('name')} />
                      {errors.name && (
                        <FormErrorMessage>
                          {errors.name.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={!!errors.slug}>
                      <FormLabel>Event URL</FormLabel>
                      <InputGroup>
                        <InputLeftAddon
                          children={`${environment.appBaseUrl}/${currentProgram.publicId}/`}
                        />
                        <Input {...register('slug')} />
                      </InputGroup>
                      <FormHelperText>
                        Only uppercase & lowercase letters, numbers, dash (-)
                        and underscore (_)
                      </FormHelperText>
                      <FormErrorMessage>
                        {errors.slug?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </Box>
              </Box>

              <Box>
                <Text fontSize="3xl">Description</Text>
                <Text mb={4}>
                  Tell your participants about your event and why they should
                  come. You can add this later.
                </Text>
                <Box rounded="lg" bg="bg-surface" p={5}>
                  <FormControl isInvalid={!!errors.summary}>
                    <FormLabel>Event description</FormLabel>
                    <Textarea {...register('summary')} />
                    {errors.summary && (
                      <FormErrorMessage>
                        {errors.summary.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Box>
              </Box>

              <Box>
                <Text fontSize="3xl">Date & Time</Text>
                <Text mb={4}>
                  Tell participants when your event starts and ends or select
                  coming soon if you don't have a date yet.
                </Text>
                <Box rounded="lg" bg="bg-surface" p={5}>
                  <Tabs
                    onChange={(index) => {
                      setValue('dateTimeType', dateTimeTypes[index]);
                      setDateTimeActiveTab(index);
                    }}
                  >
                    <TabList>
                      <Tab>Details</Tab>
                      <Tab>TBD</Tab>
                    </TabList>
                    <TabPanels mt={2}>
                      <TabPanel p={0}>
                        <Stack spacing={5}>
                          <FormControl
                            isInvalid={
                              !!errors.startDate || !!errors.startDateTime
                            }
                          >
                            <FormLabel>Event start</FormLabel>
                            <HStack spacing={5}>
                              <DatePickerInput
                                value={startDate}
                                onChange={(v) => setValue('startDate', v)}
                              />
                              <TimePickerInput
                                value={{
                                  hours: startDate.getHours(),
                                  minutes: startDate.getMinutes(),
                                }}
                                onChange={(v) => setValue('startTime', v)}
                              />
                            </HStack>
                            {errors.startDate && (
                              <FormErrorMessage>
                                {errors.startDate.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                          <FormControl
                            isInvalid={!!errors.endDate || !!errors.endDateTime}
                          >
                            <FormLabel>Event end</FormLabel>
                            <HStack spacing={5}>
                              <DatePickerInput
                                value={endDate}
                                onChange={(v) => setValue('endDate', v)}
                              />
                              <TimePickerInput
                                value={{
                                  hours: endDate.getHours(),
                                  minutes: endDate.getMinutes(),
                                }}
                                onChange={(v) => setValue('endTime', v)}
                              />
                            </HStack>
                            {(errors.endDate || errors.endDateTime) && (
                              <FormErrorMessage>
                                {errors.endDate?.message}
                                {errors.endDateTime?.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                          <FormControl>
                            <FormLabel>Time zone</FormLabel>
                            <Select
                              {...register('timeZone')}
                              placeholder="Select a time zone"
                            >
                              {timeZones.map((tz) => (
                                <option key={tz.name} value={tz.name}>
                                  {tz.name} ({tz.alternativeName})
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </Stack>
                        <Box mt={10}>
                          <Text fontWeight="semibold" mb={3}>
                            Date & Time display options
                          </Text>
                          <Text fontSize="sm" mb={3}>
                            {startDateDisplay}
                            {watchedShowEndDate && ` - ${endDateDisplay}`}
                            {watchedShowDurationInMins
                              ? ` (${durationDisplay})`
                              : ''}
                          </Text>
                          <Stack spacing={3}>
                            <HStack>
                              <Switch isChecked isDisabled />
                              <Stack spacing={0}>
                                <Box fontSize="sm" fontWeight="medium">
                                  Show start date & time
                                </Box>
                                <Box fontSize="sm" color="gray.500">
                                  The start date and time will be displayed.
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack>
                              <Switch {...register('showEndDate')} />
                              <Stack spacing={0}>
                                <Box fontSize="sm" fontWeight="medium">
                                  Show end date & time
                                </Box>
                                <Box fontSize="sm" color="gray.500">
                                  The end date and time will be displayed.
                                </Box>
                              </Stack>
                            </HStack>
                            <HStack>
                              <Switch {...register('showDurationInMins')} />
                              <Stack spacing={0}>
                                <Box fontSize="sm" fontWeight="medium">
                                  Show duration in minutes
                                </Box>
                                <Box fontSize="sm" color="gray.500">
                                  The duration of the event will be displayed.
                                  Best for classes.
                                </Box>
                              </Stack>
                            </HStack>
                          </Stack>
                        </Box>
                      </TabPanel>
                      <TabPanel p={0}>
                        <Alert status="info">
                          <AlertIcon />
                          <AlertDescription>
                            Your event time has not been determined yet and will
                            shown as{' '}
                            <chakra.span fontWeight="semibold">
                              To be announced
                            </chakra.span>
                            .
                          </AlertDescription>
                        </Alert>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </Box>

              <Box>
                <Text fontSize="3xl">Location</Text>
                <Text mb={4}>
                  Let participants know where your events is taking place. If
                  you don't know the location, select TBD.
                </Text>
                <Box rounded="lg" bg="bg-surface" p={5}>
                  <Tabs
                    onChange={(index) => {
                      setValue('locationType', eventTypes[index]);
                      setLocationActiveTab(index);
                    }}
                  >
                    <TabList>
                      <Tab>In Person</Tab>
                      <Tab>Online</Tab>
                      <Tab>Hybrid</Tab>
                      <Tab>TBD</Tab>
                    </TabList>
                    <TabPanels pt={3}>
                      <TabPanel p={0}>
                        {locationActiveTab === 0 && (
                          <InPersonEventLocation
                            errors={errors}
                            setValue={setValue}
                            location={inPersonLocation}
                          />
                        )}
                      </TabPanel>
                      <TabPanel p={0}>
                        {locationActiveTab === 1 && (
                          <OnlineEventLocation
                            initialStreamType={onlineStreamType}
                            register={register}
                            errors={errors}
                          />
                        )}
                      </TabPanel>
                      <TabPanel p={0}>
                        {locationActiveTab === 2 && (
                          <Stack spacing={5}>
                            <InPersonEventLocation
                              errors={errors}
                              setValue={setValue}
                              location={inPersonLocation}
                            />
                            <OnlineEventLocation
                              initialStreamType={onlineStreamType}
                              register={register}
                              errors={errors}
                            />
                          </Stack>
                        )}
                      </TabPanel>
                      <TabPanel p={0}>
                        <Alert status="info">
                          <AlertIcon />
                          <AlertDescription>
                            Your event location has not been determined yet and
                            will shown as{' '}
                            <chakra.span fontWeight="semibold">
                              To be announced
                            </chakra.span>
                            .
                          </AlertDescription>
                        </Alert>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </Box>
            </Stack>
            <Box>
              <Image src="/assets/images/astro1.svg" />
            </Box>
          </Stack>
          <Flex w="full" justifyContent="end" pt={10}>
            <HStack spacing={10}>
              <Button variant="ghost">Cancel</Button>
              <Button colorScheme="blue" w={160} type="submit">
                Next
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
    </chakra.form>
  );
}

export default EventGeneralInfoSection;
