import { useState } from 'react';
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  useDisclosure,
} from '@chakra-ui/react';
import AvatarEditor from 'react-avatar-editor';
import {
  convertCroppedRectangleToXY,
  getImageDimensionsFromFile,
} from '@bookabl/client/util';
import { FaSearchMinus, FaSearchPlus } from 'react-icons/fa';
import { urlToFile } from '../utils/utils';

export interface SelectedImage {
  imageFile: File;
  croppedImageDataUrl: string;
  croppedRectangle: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

interface UploadImageModalProps {
  onImageSelected: (selectedImage: SelectedImage) => void;
  editor?: {
    width: number;
    height: number;
  };
}

function UploadImageModal({
  onImageSelected,
  editor = { width: 500, height: 500 },
}: UploadImageModalProps) {
  const [file, setFile] = useState<File>();
  const [imageZoom, setImageZoom] = useState<number>(1);
  const [imageDimensions, setImageDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const { isOpen, onOpen, onClose } = useDisclosure();
  let fileInput: HTMLInputElement | null = null;
  let avatarEditor: AvatarEditor | null = null;

  const imageSelected = () => {
    const handle = async () => {
      if (avatarEditor && file) {
        const imageFile = await urlToFile(
          avatarEditor.getImage().toDataURL(),
          file.name,
          'text/plain'
        );

        onImageSelected({
          imageFile,
          croppedImageDataUrl: avatarEditor.getImage().toDataURL(),
          croppedRectangle: convertCroppedRectangleToXY(
            imageDimensions,
            avatarEditor.getCroppingRect()
          ),
        });
        closeModal();
      }
    };

    void handle();
  };

  const closeModal = () => {
    if (fileInput) {
      fileInput.value = '';
    }
    onClose();
  };

  return (
    <>
      <Button onClick={() => fileInput?.click()} ml="4">
        Upload image
      </Button>
      <input
        hidden
        type="file"
        accept="jpg,.png,.jpeg"
        ref={(el) => (fileInput = el)}
        onChange={(e) => {
          const selectedFile = e.target?.files?.[0];
          if (selectedFile) {
            getImageDimensionsFromFile(selectedFile).then((dimensions) => {
              setImageDimensions(dimensions);
              setFile(selectedFile);
              onOpen();
            });
          }
        }}
      />

      <Modal isOpen={isOpen} onClose={closeModal} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent="space-between">
              <Button variant="outline" size="sm" onClick={closeModal}>
                Cancel
              </Button>
              <Button size="sm" onClick={imageSelected} variant="primary">
                Apply
              </Button>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <Flex justifyContent="center">
              {file && (
                <AvatarEditor
                  ref={(el) => (avatarEditor = el)}
                  image={file}
                  width={editor.width}
                  height={editor.height}
                  scale={imageZoom}
                />
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Icon as={FaSearchMinus} mr="5" />
            <RangeSlider
              aria-label={['zoom out', 'zoom in']}
              defaultValue={[1]}
              min={1}
              max={5}
              step={0.01}
              onChange={(values) => setImageZoom(values[0])}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} />
            </RangeSlider>
            <Icon as={FaSearchPlus} ml="5" />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UploadImageModal;
