import { IsNumber, IsOptional } from 'class-validator';

export class CroppedRectangleDto {
  @IsNumber()
  x!: number;

  @IsNumber()
  y!: number;

  @IsNumber()
  width!: number;

  @IsNumber()
  height!: number;

  @IsOptional()
  @IsNumber()
  finalHeight?: number;

  @IsOptional()
  @IsNumber()
  finalWidth?: number;

  constructor(props?: CroppedRectangleDto) {
    Object.assign(this, props || {});
  }
}
