import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';

import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  FiFacebook,
  FiImage,
  FiInstagram,
  FiTrash,
  FiTrash2,
  FiTwitter,
} from 'react-icons/fi';
import { organizationRoute, programRoute } from '../../../utils/routing-utils';
import { PageContext } from '../../../model/page-context';
import useCurrentProgram from '../../../context/current-program-context';
import useAdminSession from '../../../context/admin-session-context';
import { useMutation } from 'react-query';
import {
  HostClient,
  MediaClient,
  useGetHostForProgram,
} from '@bookabl/client/api-client';
import { ProgramDto } from '@bookabl/shared/model/program';
import { OrganizationDto } from '@bookabl/shared/model/organization';
import { useEffect, useState } from 'react';
import { logger } from '@bookabl/client/util';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import UploadImageModal, {
  SelectedImage,
} from '../../../components/upload-image-modal';
import { CroppedRectangleDto } from '@bookabl/shared/model/integration';
import { imageUrl } from '../../../utils/utils';
import {
  CreateEventHostDto,
  UpdateEventHostDto,
} from '@bookabl/shared/model/event';
import { TbBrandTiktok } from 'react-icons/tb';

function contextRoute(
  pageContext: PageContext,
  orgId: string,
  programId: string | undefined,
  path: string
) {
  return pageContext === PageContext.Organization
    ? organizationRoute(orgId, path)
    : programRoute(orgId, programId || '', path);
}

interface DeleteSponsorButtonProps {
  pageContext: PageContext;
  currentOrganization: OrganizationDto;
  currentProgram: ProgramDto;
  sponsorId: string;
  isLoading?: boolean;
}

function DeleteHostButton({
  pageContext,
  currentOrganization,
  currentProgram,
  sponsorId,
  isLoading,
}: DeleteSponsorButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteHost = useMutation(() =>
    HostClient.deleteHostForProgram(
      currentProgram.orgId,
      currentProgram.id,
      sponsorId
    )
  );
  const toast = useToast();
  const navigate = useNavigate();

  const confirmDelete = async () => {
    deleteHost.mutate(undefined, {
      onSuccess: () => {
        navigate(
          contextRoute(
            pageContext,
            currentOrganization.id,
            currentProgram.id,
            '/hosts'
          )
        );
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'There was an error removing the host',
        });
        logger.error(`Error removing host`, deleteHost.error);
      },
    });
  };

  return (
    <>
      <Button
        colorScheme="red"
        leftIcon={<FiTrash2 />}
        isLoading={isLoading || deleteHost.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to remove this sponsor?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                variant="ghost"
                onClick={onClose}
                isDisabled={deleteHost.isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={deleteHost.isLoading}
                onClick={confirmDelete}
              >
                Yes, remove
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const formSchema = yup.object({
  name: yup.string().required('Name is required'),
  published: yup.boolean().required('Published is required'),
  slug: yup
    .string()
    .required('Url required')
    .matches(
      /^[a-zA-Z0-9_\-.]+$/,
      'Url must be alphanumeric, underscores, dashes and periods'
    ),
  summary: yup.string().max(500, 'Maximum 500 characters'),
});

interface EditHostForm {
  name: string;
  published: boolean;
  slug: string;
  coverImageUrl?: string;
  profileImageUrl?: string;
  location?: string;
  summary?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  linkedInUrl?: string;
  tiktokUrl?: string;
  twitterUrl?: string;
}

export interface EditSponsorPageProps {
  pageContext: PageContext.Program;
}

export function EditHostPage({ pageContext }: EditSponsorPageProps) {
  const { id } = useParams() as { id: string };
  const isCreate = id === 'new';
  const { currentOrganization } = useAdminSession();
  const { currentProgram } = useCurrentProgram();
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedCoverImageUrl, setSelectedCoverImageUrl] =
    useState<SelectedImage>();
  const [selectedProfileImageUrl, setSelectedProfileImageUrl] =
    useState<SelectedImage>();
  const [coverImageUrl, setCoverImageUrl] = useState<string>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();

  const { data: host, isLoading } = useGetHostForProgram(
    currentOrganization.id,
    currentProgram.id,
    id,
    !isCreate
  );

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<EditHostForm>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      published: false,
    },
  });

  const watchedPublished = watch('published');

  const createHost = useMutation((dto: CreateEventHostDto) =>
    HostClient.createHostForProgram(
      currentOrganization.id,
      currentProgram.id,
      dto
    )
  );

  const updateHost = useMutation((dto: UpdateEventHostDto) =>
    HostClient.updateHostForProgram(
      currentOrganization.id,
      currentProgram.id,
      id,
      dto
    )
  );

  const uploadMedia = useMutation(
    ({
      file,
      hostId,
      croppedRectangle,
      key,
      mediaType,
    }: {
      file: File;
      hostId: string;
      croppedRectangle?: CroppedRectangleDto;
      key: string;
      mediaType: 'profileImage' | 'coverImage';
    }) => {
      return MediaClient.generateSignature({ key }).then(({ signature }) =>
        MediaClient.upload(file, signature, croppedRectangle).then(
          (uploadRes) =>
            HostClient.setHostMediaForProgram(
              currentOrganization.id,
              currentProgram.id,
              hostId,
              uploadRes.path,
              mediaType
            )
        )
      );
    }
  );

  useEffect(() => {
    if (!host) {
      return;
    }

    if (!isDirty) {
      if (host.coverImageUrl) {
        setCoverImageUrl(imageUrl(host.coverImageUrl));
      }

      if (host.profileImageUrl) {
        setProfileImageUrl(imageUrl(host.profileImageUrl));
      }

      reset({
        name: host.name,
        published: host.published,
        slug: host.slug,
        coverImageUrl: host.coverImageUrl,
        profileImageUrl: host.profileImageUrl,
        location: host.location,
        summary: host.summary,
        facebookUrl: host.facebookUrl,
        instagramUrl: host.instagramUrl,
        linkedInUrl: host.linkedInUrl,
        tiktokUrl: host.tiktokUrl,
        twitterUrl: host.twitterUrl,
      });
    }
  }, [host, isDirty, reset]);

  const onProfileImageSelected = (selectedImage: SelectedImage) => {
    setProfileImageUrl(selectedImage.croppedImageDataUrl);
    setSelectedProfileImageUrl(selectedImage);
  };

  const onCoverImageSelected = (selectedImage: SelectedImage) => {
    setCoverImageUrl(selectedImage.croppedImageDataUrl);
    setSelectedCoverImageUrl(selectedImage);
  };

  const notifyOnSuccess = () => {
    toast({
      status: 'success',
      description: 'Host saved successfully.',
    });
    navigate(
      contextRoute(
        pageContext,
        currentOrganization.id,
        currentProgram.id,
        '/hosts'
      )
    );
  };

  const tryUploadImageAndNotifySuccess = ({ id: hostId }: { id: string }) => {
    const uploadProfileImage = (additionalUpload?: () => void) => {
      let croppedRectangle: CroppedRectangleDto | undefined = undefined;
      if (selectedProfileImageUrl?.croppedRectangle) {
        croppedRectangle = {
          ...selectedProfileImageUrl.croppedRectangle,
          finalHeight: 250,
          finalWidth: 250,
        };

        const key = `/orgs/${currentOrganization.id}/programs/${currentProgram.id}/media/hosts`;

        let options;
        if (additionalUpload) {
          options = { onSuccess: additionalUpload, onError: additionalUpload };
        } else {
          options = { onSuccess: notifyOnSuccess, onError: notifyOnSuccess };
        }

        uploadMedia.mutate(
          {
            hostId,
            key,
            croppedRectangle,
            file: selectedProfileImageUrl.imageFile,
            mediaType: 'profileImage',
          },
          options
        );
      }
    };

    const uploadCoverImage = () => {
      let croppedRectangle: CroppedRectangleDto | undefined = undefined;
      if (selectedCoverImageUrl?.croppedRectangle) {
        croppedRectangle = {
          ...selectedCoverImageUrl.croppedRectangle,
          finalHeight: 250,
          finalWidth: 500,
        };

        const key = `/orgs/${currentOrganization.id}/programs/${currentProgram.id}/media/hosts`;

        uploadMedia.mutate(
          {
            hostId,
            key,
            croppedRectangle,
            file: selectedCoverImageUrl.imageFile,
            mediaType: 'coverImage',
          },
          { onSuccess: notifyOnSuccess, onError: notifyOnSuccess }
        );
      }
    };

    if (selectedProfileImageUrl && selectedCoverImageUrl) {
      uploadProfileImage(uploadCoverImage);
    } else if (selectedProfileImageUrl) {
      uploadProfileImage();
    } else if (selectedCoverImageUrl) {
      uploadCoverImage();
    } else {
      notifyOnSuccess();
    }
  };

  const onSubmit: SubmitHandler<EditHostForm> = async (data) => {
    if (isCreate) {
      await createHost.mutate(data, {
        onSuccess: tryUploadImageAndNotifySuccess,
        onError: () => {
          toast({
            status: 'error',
            description: 'There was an error creating the host.',
          });
        },
      });
    } else {
      await updateHost.mutate(data, {
        onSuccess: tryUploadImageAndNotifySuccess,
        onError: () => {
          toast({
            status: 'error',
            description: 'There was an error updating the host.',
          });
        },
      });
    }
  };

  return (
    <AdminPageLayout
      title="Manage host"
      description="Update host details"
      isLoading={isLoading}
    >
      <chakra.form h="full" onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir="column" h="full" justifyContent="space-between">
          <Stack w="container.sm" spacing={4}>
            <FormControl isInvalid={!!errors.published}>
              <HStack>
                <Switch size="lg" {...register('published')} />
                <FormLabel>
                  {watchedPublished ? 'Published' : 'Unpublished'}
                </FormLabel>
              </HStack>
              <FormErrorMessage>{errors.published?.message}</FormErrorMessage>
            </FormControl>
            <HStack spacing={10}>
              <FormControl>
                <FormLabel>Profile image</FormLabel>
                {profileImageUrl ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    w="250px"
                    h="250px"
                    border="2px dashed"
                    borderColor="gray.200"
                    rounded="lg"
                  >
                    <Image
                      rounded="lg"
                      src={profileImageUrl}
                      w="full"
                      h="full"
                    />
                    <HStack
                      rounded="lg"
                      _hover={{ visibility: 'visible', opacity: 0.9 }}
                      opacity={0}
                      spacing={40}
                      position="absolute"
                      bgColor="gray.200"
                      px={3}
                      py={3}
                    >
                      {/*
                        <IconButton
                          variant="outline"
                          size="xl"
                          aria-label="Crop image"
                          icon={<FiCrop />}
                          onClick={() => {
                            console.log(selectedImage);
                          }}
                        />
                        */}
                      <IconButton
                        variant="outline"
                        size="xl"
                        aria-label="Remove image"
                        icon={<FiTrash />}
                        onClick={() => {
                          setValue('profileImageUrl', '', {
                            shouldDirty: true,
                          });
                          setProfileImageUrl(undefined);
                        }}
                      />
                    </HStack>
                  </Flex>
                ) : (
                  <Flex
                    w="250px"
                    h="250px"
                    justifyContent="center"
                    alignItems="center"
                    rounded="lg"
                    border="2px dashed"
                    borderColor="gray.200"
                  >
                    <VStack>
                      <Icon color="gray.200" as={FiImage} boxSize={20} />
                      <UploadImageModal
                        onImageSelected={onProfileImageSelected}
                        editor={{ height: 250, width: 250 }}
                      />
                    </VStack>
                  </Flex>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Cover image</FormLabel>
                {coverImageUrl ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    w="500px"
                    h="250px"
                    border="2px dashed"
                    borderColor="gray.200"
                    rounded="lg"
                  >
                    <Image rounded="lg" src={coverImageUrl} w="full" h="full" />
                    <HStack
                      rounded="lg"
                      _hover={{ visibility: 'visible', opacity: 0.9 }}
                      opacity={0}
                      spacing={40}
                      position="absolute"
                      bgColor="gray.200"
                      px={3}
                      py={3}
                    >
                      {/*
                        <IconButton
                          variant="outline"
                          size="xl"
                          aria-label="Crop image"
                          icon={<FiCrop />}
                          onClick={() => {
                            console.log(selectedImage);
                          }}
                        />
                        */}
                      <IconButton
                        variant="outline"
                        size="xl"
                        aria-label="Remove image"
                        icon={<FiTrash />}
                        onClick={() => {
                          setValue('coverImageUrl', '', {
                            shouldDirty: true,
                          });
                          setCoverImageUrl(undefined);
                        }}
                      />
                    </HStack>
                  </Flex>
                ) : (
                  <Flex
                    w="500px"
                    h="250px"
                    justifyContent="center"
                    alignItems="center"
                    rounded="lg"
                    border="2px dashed"
                    borderColor="gray.200"
                  >
                    <VStack>
                      <Icon color="gray.200" as={FiImage} boxSize={20} />
                      <UploadImageModal
                        onImageSelected={onCoverImageSelected}
                        editor={{ height: 250, width: 500 }}
                      />
                    </VStack>
                  </Flex>
                )}
              </FormControl>
            </HStack>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.slug}>
              <FormLabel>URL</FormLabel>
              <InputGroup>
                <InputLeftAddon>https://changeme.bookabl.io/</InputLeftAddon>
                <Input {...register('slug')} />
              </InputGroup>
              <FormErrorMessage>{errors.slug?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.summary}>
              <FormLabel>Summary</FormLabel>
              <Textarea {...register('summary')} />
              <FormErrorMessage>{errors.summary?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.location}>
              <FormLabel>Location</FormLabel>
              <Input {...register('location')} />
              <FormErrorMessage>{errors.location?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.facebookUrl}>
              <FormLabel>Facebook</FormLabel>
              <InputGroup>
                <InputLeftAddon>
                  <FiFacebook />
                </InputLeftAddon>
                <Input {...register('facebookUrl')} />
              </InputGroup>
              <FormErrorMessage>{errors.facebookUrl?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.instagramUrl}>
              <FormLabel>Instagram</FormLabel>
              <InputGroup>
                <InputLeftAddon>
                  <FiInstagram />
                </InputLeftAddon>
                <Input {...register('instagramUrl')} />
              </InputGroup>
              <FormErrorMessage>
                {errors.instagramUrl?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.tiktokUrl}>
              <FormLabel>TikTok</FormLabel>
              <InputGroup>
                <InputLeftAddon>
                  <TbBrandTiktok />
                </InputLeftAddon>
                <Input {...register('tiktokUrl')} />
              </InputGroup>
              <FormErrorMessage>{errors.tiktokUrl?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.twitterUrl}>
              <FormLabel>Twitter</FormLabel>
              <InputGroup>
                <InputLeftAddon>
                  <FiTwitter />
                </InputLeftAddon>
                <Input {...register('twitterUrl')} />
              </InputGroup>
              <FormErrorMessage>{errors.twitterUrl?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <HStack
            borderTop="1px solid"
            borderColor="gray.200"
            justifyContent="space-between"
            mt={6}
            pt={6}
          >
            <Box>
              {!isCreate && (
                <DeleteHostButton
                  pageContext={pageContext}
                  currentOrganization={currentOrganization}
                  currentProgram={currentProgram}
                  sponsorId={id}
                  isLoading={createHost.isLoading || uploadMedia.isLoading}
                />
              )}
            </Box>
            <HStack spacing={4}>
              <Button
                isLoading={createHost.isLoading || uploadMedia.isLoading}
                variant="outline"
                as={Link}
                to={contextRoute(
                  pageContext,
                  currentOrganization.id,
                  currentProgram.id,
                  '/hosts'
                )}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={createHost.isLoading || uploadMedia.isLoading}
              >
                Save changes
              </Button>
            </HStack>
          </HStack>
        </Flex>
      </chakra.form>
    </AdminPageLayout>
  );
}

export default EditHostPage;
