import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import {
  Avatar,
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { organizationRoute } from '../../../utils/routing-utils';
import useAdminSession from '../../../context/admin-session-context';
import {
  OrganizationClient,
  useGetOrganizationUser,
} from '@bookabl/client/api-client';
import {
  CreateOrganizationUserDto,
  OrganizationRoleDto,
} from '@bookabl/shared/model/organization';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { isAxiosError, logger } from '@bookabl/client/util';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from '../../../hooks/use-toast';

interface OrganizationUserForm {
  email?: string;
  role: OrganizationRoleDto;
}

const createFormSchema = yup.object({
  email: yup
    .string()
    .required('User email is required')
    .email('Must be an email'),
  role: yup.string().required('Role is required'),
});

const updateFormSchema = yup.object({
  role: yup.string().required('Role is required'),
});

export function EditOrganizationTeamMemberPage() {
  const { id } = useParams() as { id: string };
  const isCreate = id === 'new';
  const { currentOrganization } = useAdminSession();
  const { data: orgUser } = useGetOrganizationUser(
    currentOrganization.id,
    id,
    isCreate
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDeleting, setIsDeleting] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean(true);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<OrganizationUserForm>({
    resolver: yupResolver(isCreate ? createFormSchema : updateFormSchema),
  });
  const toast = useToast();

  useEffect(() => {
    if (!orgUser) {
      if (isCreate) {
        setIsLoading.off();
      }

      return;
    }

    if (!isDirty) {
      reset({ role: orgUser.role });
      setIsLoading.off();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUser]);

  const onSubmit: SubmitHandler<OrganizationUserForm> = async ({
    role,
    email,
  }) => {
    try {
      if (isCreate && !!email) {
        try {
          await OrganizationClient.createOrganizationUser(
            currentOrganization.id,
            new CreateOrganizationUserDto({ role, email })
          );
        } catch (err) {
          if (
            isAxiosError(err) &&
            err.response?.data?.code === 'user.not.found'
          ) {
            setError('email', { type: 'custom', message: 'User not found' });
            return;
          }

          // noinspection ExceptionCaughtLocallyJS
          throw err;
        }
      } else {
        await OrganizationClient.updateOrganizationUser(
          currentOrganization.id,
          id,
          role
        );
      }
      toast({
        status: 'success',
        description: 'Changes saved successfully',
      });
      navigate(organizationRoute(currentOrganization.id, `/team`));
    } catch (err) {
      toast({
        status: 'error',
        description: 'There was an error saving the team member',
      });
      logger.error(`Error saving team member`, err);
    }
  };

  if (isLoading) {
    return <div />;
  }

  const confirmDelete = async () => {
    setIsDeleting.on();
    try {
      await OrganizationClient.deleteOrganizationUser(
        currentOrganization.id,
        id
      );
    } catch (err) {
      toast({
        status: 'error',
        description: 'There was an error removing the team member',
      });
      logger.error(`Error removing team member`, err);
    } finally {
      setIsDeleting.off();
      onClose();
    }
  };

  return (
    <AdminPageLayout
      title="Team member information"
      description="Manage team member roles and permissions."
    >
      {orgUser && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Are you sure?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to remove {orgUser.user.name} from your
              team?
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  variant="ghost"
                  onClick={onClose}
                  isDisabled={isDeleting}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={confirmDelete}
                  isLoading={isDeleting}
                >
                  Yes, remove
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <chakra.form h="full" onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir="column" h="full">
          <Flex
            flexDir="column"
            flex="1"
            h="full"
            justifyContent="space-between"
          >
            <Stack spacing={8}>
              {orgUser && (
                <HStack spacing={2}>
                  <Avatar name={orgUser.user.name} boxSize={20} />
                  <Box>
                    <Text fontSize="3xl" fontWeight="medium">
                      {orgUser.user.name}
                    </Text>
                    <Text color="muted">{orgUser.user.email}</Text>
                  </Box>
                </HStack>
              )}
              <Box w="380px">
                <VStack spacing={5}>
                  {isCreate && (
                    <FormControl isInvalid={!!errors.email}>
                      <FormLabel>User email</FormLabel>
                      <Input
                        {...register('email', {
                          required: 'User email is required',
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: 'Must be an email address',
                          },
                        })}
                      />
                      <FormErrorMessage>
                        {errors.email && errors.email.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                  <FormControl isInvalid={!!errors.role}>
                    <FormLabel>Role</FormLabel>
                    <Select {...register('role')}>
                      {!isCreate && (
                        <option value={OrganizationRoleDto.OrgOwner} disabled>
                          Owner
                        </option>
                      )}
                      <option value={OrganizationRoleDto.OrgAdmin}>
                        Admin
                      </option>
                      <option value={OrganizationRoleDto.OrgMember}>
                        Member
                      </option>
                      <option value={OrganizationRoleDto.OrgProgramAccess}>
                        Program Access
                      </option>
                    </Select>
                    <FormErrorMessage>
                      {errors.role && errors.role.message}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>
              </Box>
            </Stack>
            <HStack
              borderTop="1px solid"
              borderColor="gray.200"
              justifyContent="space-between"
              pt={6}
            >
              <Box>
                {!isCreate && (
                  <Button
                    colorScheme="red"
                    isLoading={isDeleting}
                    leftIcon={<FiTrash2 />}
                    onClick={onOpen}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <HStack spacing={4}>
                <Button
                  variant="outline"
                  as={Link}
                  to={organizationRoute(currentOrganization.id, '/team')}
                  isDisabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="blue"
                  isLoading={isSubmitting}
                >
                  {isCreate ? 'Add User' : 'Save Changes'}
                </Button>
              </HStack>
            </HStack>
          </Flex>
        </Flex>
      </chakra.form>
    </AdminPageLayout>
  );
}

export default EditOrganizationTeamMemberPage;
