/**
 * Build organization route
 *
 * @param orgId organization id
 * @param path path to route to
 */
export const organizationRoute = (orgId: string, path = '') =>
  `/admin/orgs/${orgId}${path}`;

/**
 * Build program route
 *
 * @param orgId organization id
 * @param programId program id
 * @param path path to route to
 */
export const programRoute = (orgId: string, programId: string, path = '') =>
  `/admin/orgs/${orgId}/programs/${programId}${path}`;
