import { AutoMap } from '@automapper/classes';
import {
  IsNotEmpty,
  IsOptional,
  IsString,
  Matches,
  MaxLength,
} from 'class-validator';

export class CreateRegistrationProfileDto {
  @IsOptional()
  @IsString()
  @AutoMap()
  programId?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  eventId?: string;

  @IsString()
  @IsNotEmpty()
  @MaxLength(36)
  @Matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]{2,35}$/, {
    message:
      'formId must start with a letter or number and only contain letter, numbers, underscores or dashes',
  })
  @AutoMap()
  formId!: string;

  @IsString()
  @IsNotEmpty()
  @AutoMap()
  name!: string;

  constructor(props?: CreateRegistrationProfileDto) {
    if (!props) return;

    this.name = props.name;
    this.formId = props.formId;
    this.eventId = props.eventId;
    this.programId = props.programId;
  }
}
