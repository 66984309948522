import { ProgramDto, ProgramRoleDto } from '@bookabl/shared/model/program';
import { Type } from 'class-transformer';
import { AutoMap } from '@automapper/classes';

export class ProgramUserDto {
  @AutoMap({ typeFn: () => String })
  role!: ProgramRoleDto;

  @AutoMap({ typeFn: () => ProgramDto })
  @Type(() => ProgramDto)
  program!: ProgramDto;
}
