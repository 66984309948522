import { Box, Button, Circle, Stack, Text } from '@chakra-ui/react';
import { FiArrowLeft, FiCheck } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import useCurrentEvent, { EditEventState } from '../context/edit-event-context';

const pageOrder: EditEventState[] = [
  'EventSelection',
  'GeneralInfo',
  'Details',
  'Payment',
  'Publish',
];

interface SidebarButtonProps {
  pageState: EditEventState;
  currentPageState: EditEventState;
  label: string;
  step?: number;
}

function SidebarButton({
  pageState,
  currentPageState,
  label,
  step,
}: SidebarButtonProps) {
  const { setPageState } = useCurrentEvent();
  function isEventSelection() {
    return currentPageState === 'EventSelection';
  }

  return (
    <Button
      variant="ghost"
      ps={2}
      py={6}
      leftIcon={
        <Circle
          // bg={currentPageState === pageState ? 'purple.500' : 'blue.500'}
          bg="blue.500"
          color="white"
          size={7}
        >
          {!isEventSelection() &&
          pageOrder.indexOf(currentPageState) > pageOrder.indexOf(pageState) ? (
            <FiCheck />
          ) : (
            <Box>{step}</Box>
          )}
        </Circle>
      }
      w="full"
      justifyContent="start"
      disabled={
        isEventSelection() ||
        pageOrder.indexOf(currentPageState) < pageOrder.indexOf(pageState)
      }
      onClick={() => setPageState(pageState)}
    >
      {label}
    </Button>
  );
}

export function EventSidebar() {
  const { isCreate, pageState } = useCurrentEvent();

  return (
    <Stack w="full">
      <Box>
        <Box mb={4}>
          <Button
            leftIcon={<FiArrowLeft />}
            variant="link"
            as={Link}
            to="./.."
            replace={isCreate}
          >
            Back to event dashboard
          </Button>
        </Box>
        <Text fontWeight="semibold" fontSize="2xl" mb={4}>
          {isCreate ? 'Create' : 'Edit'} Event
        </Text>
        <SidebarButton
          pageState="GeneralInfo"
          currentPageState={pageState}
          step={1}
          label="General Info"
        />
        <SidebarButton
          pageState="Details"
          currentPageState={pageState}
          step={2}
          label="Details"
        />
        <SidebarButton
          pageState="Payment"
          currentPageState={pageState}
          step={3}
          label="Payments"
        />
        <SidebarButton
          pageState="Publish"
          currentPageState={pageState}
          step={4}
          label="Publish"
        />
      </Box>
    </Stack>
  );
}

export default EventSidebar;
