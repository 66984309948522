import '@fontsource/inter/variable.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import SignInPage from './features/authentication/pages/sign-in-page';
import { AdminSessionProvider } from './context/admin-session-context';
import CreateProgramPage from './features/organization/pages/create-program-page';
import { CurrentProgramProvider } from './context/current-program-context';
import AdminLayout from './components/shared/layouts/admin-layout';
import PlaceholderLayout from './components/shared/layouts/placeholder-layout';
import OrganizationTeamPage from './features/organization/pages/organization-team-page';
import EditOrganizationTeamMemberPage from './features/organization/pages/edit-organization-team-member-page';
import OrganizationIntegrationPage from './features/organization/pages/organization-integration-page';
import { OrganizationStripeIntegrationPage } from './features/organization/pages/stripe-integration-page';
import InstallIntegrationRouterPage from './features/integration/pages/install-integration-router-page';
import IntegrationPage from './features/integration/pages/integration-page';
import ProgramStripeIntegrationPage from './features/program/pages/program-stripe-integration-page';
import EditStripeIntegrationPage from './features/integration/pages/edit-stripe-integration-page';
import EventManagementPage from './features/event/pages/event-management-page';
import EditEventPage from './features/event/pages/edit-event-page/edit-event-page';
import OrganizationPage from './features/organization/pages/organization-page';
import SponsorPage from './features/sponsor/pages/sponsor-page';
import EditSponsorPage from './features/sponsor/pages/edit-sponsor-page';
import { PageContext } from './model/page-context';
import HostPage from './features/host/pages/host-page';
import EditHostPage from './features/host/pages/edit-host-page';
import { CurrentEventProvider } from './features/event/context/edit-event-context';

function ProgramContextWrapper() {
  return (
    <CurrentProgramProvider>
      <Outlet />
    </CurrentProgramProvider>
  );
}

function AdminLayoutWrapper() {
  return (
    <AdminSessionProvider>
      <CurrentEventProvider>
        <AdminLayout />
      </CurrentEventProvider>
    </AdminSessionProvider>
  );
}

export function App() {
  return (
    <Routes>
      <Route path="/login" element={<SignInPage />} />
      <Route path="/admin/*" element={<AdminLayoutWrapper />}>
        <Route
          path="oauth/install/stripe"
          element={<InstallIntegrationRouterPage />}
        />
        <Route
          path="orgs/:orgId/programs/*"
          element={<ProgramContextWrapper />}
        >
          <Route
            path=":programId/hosts/:id"
            element={<EditHostPage pageContext={PageContext.Program} />}
          />
          <Route
            path=":programId/hosts"
            element={<HostPage pageContext={PageContext.Program} />}
          />
          <Route
            path=":programId/sponsors/:id"
            element={<EditSponsorPage pageContext={PageContext.Program} />}
          />
          <Route
            path=":programId/sponsors"
            element={<SponsorPage pageContext={PageContext.Program} />}
          />
          <Route path=":programId/events/:id" element={<EditEventPage />} />
          <Route path=":programId/events" element={<EventManagementPage />} />
          <Route
            path=":programId/integrations/stripe/:id"
            element={<EditStripeIntegrationPage context="PROGRAM" />}
          />
          <Route
            path=":programId/integrations/stripe"
            element={<ProgramStripeIntegrationPage />}
          />
          <Route path=":programId/integrations" element={<IntegrationPage />} />
          <Route
            path=":programId/team"
            element={<PlaceholderLayout label="Program Team Page" />}
          />
          <Route
            path=":programId"
            element={
              <PlaceholderLayout label="Program Placeholder Dashboard" />
            }
          />
        </Route>
        <Route
          path="orgs/:orgId/integrations/stripe/:id"
          element={<EditStripeIntegrationPage context="ORGANIZATION" />}
        />
        <Route
          path="orgs/:orgId/integrations/stripe"
          element={<OrganizationStripeIntegrationPage />}
        />
        <Route
          path="orgs/:orgId/integrations"
          element={<OrganizationIntegrationPage />}
        />
        <Route
          path="orgs/:orgId/team/:id"
          element={<EditOrganizationTeamMemberPage />}
        />
        <Route path="orgs/:orgId/team" element={<OrganizationTeamPage />} />
        <Route path="orgs/:orgId/new-program" element={<CreateProgramPage />} />
        <Route path="orgs/:orgId" element={<OrganizationPage />} />
      </Route>
    </Routes>
  );
}

export default App;
