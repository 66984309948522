import { AutoMap } from '@automapper/classes';
import { IsOptional, IsString } from 'class-validator';
import { CreateSponsorDto } from './create-sponsor.dto';

export class SponsorDto extends CreateSponsorDto {
  @AutoMap()
  @IsString()
  id!: string;

  @AutoMap()
  @IsString()
  orgId!: string;

  @AutoMap()
  @IsString()
  @IsOptional()
  programId?: string;

  constructor(props?: SponsorDto) {
    super(props);
    Object.assign(this, props || {});
  }
}
