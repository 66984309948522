import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import CreateProgramForm from '../components/create-program-form/create-program-form';
import useAdminSession from '../../../context/admin-session-context';
import { Link } from 'react-router-dom';

export function CreateProgramPage() {
  const { currentOrganization } = useAdminSession();
  return (
    <Flex direction="column" height="full">
      <Box textAlign="right">
        <Button
          size="sm"
          variant="outline"
          as={Link}
          to={`/admin/orgs/${currentOrganization?.id}`}
          replace={true}
        >
          Cancel
        </Button>
      </Box>
      <Heading fontSize="2xl">Program Details</Heading>
      <Text fontSize="sm" my="2" mb="7">
        A program is similar to a team, chapter, community or organization. Add
        program details here.
      </Text>

      {currentOrganization && (
        <CreateProgramForm orgId={currentOrganization.id} />
      )}
    </Flex>
  );
}

export default CreateProgramPage;
