import { CroppedRect } from 'react-avatar-editor';
import axios, { AxiosError } from 'axios';

/**
 * Get image size from file
 * @param file
 */
const getImageDimensionsFromFile2 = (
  file: File
): Promise<{ width: number; height: number }> => {
  const url = URL.createObjectURL(file);
  const image = new Image();

  return new Promise((resolve) => {
    image.onload = function () {
      const dimensions = {
        width: image.width,
        height: image.height,
      };
      URL.revokeObjectURL(image.src);
      resolve(dimensions);
    };

    image.src = url;
  });
};

export const getImageDimensionsFromFile = getImageDimensionsFromFile2;

/**
 * Convert react-avatar-image cropped rectangle to X & Y values.
 * Example:
 *
 * Cropped Rectangle
 * {
 *   x: 0.1,
 *   y: 0.2
 *   width: 0.123
 *   height: 0.456
 * }
 *
 * Return
 * {
 *   x: 10,
 *   y: 20,
 *   width: 300
 *   height: 300
 * }
 */
export const convertCroppedRectangleToXY = (
  imageDimensions: { width: number; height: number },
  croppedRect: CroppedRect
): {
  x: number;
  y: number;
  width: number;
  height: number;
} => {
  return {
    x: Math.min(
      Math.round(imageDimensions.width * croppedRect.x),
      imageDimensions.width
    ),
    y: Math.min(
      Math.round(imageDimensions.height * croppedRect.y),
      imageDimensions.height
    ),
    width: Math.min(
      Math.round(imageDimensions.width * croppedRect.width),
      imageDimensions.width
    ),
    height: Math.min(
      Math.round(imageDimensions.height * croppedRect.height),
      imageDimensions.height
    ),
  };
};

/**
 * Type guard for checking if an error is an axios error.
 *
 * @param err error object
 * @returns true if error, false otherwise
 */
export function isAxiosError(err: unknown): err is AxiosError {
  return axios.isAxiosError(err);
}

/**
 * Check if value is undefined.
 *
 * @param val value
 */
export function isUndefined(val: unknown) {
  return typeof val === 'undefined';
}
