import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import { Button, Flex, List, ListItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';

export function EventManagementPage() {
  return (
    <AdminPageLayout title="Events" description="Manage your programs events">
      <Flex mb={5} justifyContent="end">
        <Button leftIcon={<FiPlus />} as={Link} to="./new" colorScheme="blue">
          New event
        </Button>
      </Flex>
      <List>
        <ListItem border="1px solid lightgray" p={3}>
          Event goes here
        </ListItem>
      </List>
    </AdminPageLayout>
  );
}

export default EventManagementPage;
