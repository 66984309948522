import { AutoMap } from '@automapper/classes';

interface PageDtoProps<T> {
  data: T[];
}

export class PageDto<T> {
  @AutoMap()
  data!: T[];

  constructor(props?: PageDtoProps<T>) {
    Object.assign(this, props || {});
  }
}
