import { Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export function ClassToPlainISODateFormatNoMillisUTCTimezone(
  target: unknown,
  propertyName: string
) {
  return Transform(
    ({ value }: { value: Date }) =>
      DateTime.fromJSDate(value)
        .setZone('utc')
        .startOf('second')
        .toISO({ suppressMilliseconds: true }),
    { toPlainOnly: true }
  )(target as object, propertyName);
}
