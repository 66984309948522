import { apiClient } from '../api-client';
import { PageDto } from '@bookabl/shared/model/common';
import {
  CreateOrganizationUserDto,
  OrganizationRoleDto,
  OrganizationUserDto,
} from '@bookabl/shared/model/organization';
import { plainToClass, Type } from 'class-transformer';
import { AutoMap } from '@automapper/classes';

export class OrganizationUserDtoPage extends PageDto<OrganizationUserDto> {
  @AutoMap()
  @Type(() => OrganizationUserDto)
  override data!: OrganizationUserDto[];
}

export const OrganizationClient = {
  async getOrganizationUsers(orgId: string) {
    return apiClient
      .get<PageDto<OrganizationUserDto>>(`/organizations/${orgId}/users`)
      .then((res) => plainToClass(OrganizationUserDtoPage, res.data));
  },

  async getOrganizationUser(
    orgId: string,
    id: string
  ): Promise<OrganizationUserDto> {
    return apiClient
      .get<OrganizationUserDto>(`/organizations/${orgId}/users/${id}`)
      .then((res) => plainToClass(OrganizationUserDto, res.data));
  },

  async createOrganizationUser(
    orgId: string,
    dto: CreateOrganizationUserDto
  ): Promise<OrganizationUserDto> {
    return apiClient
      .post<OrganizationUserDto>(`/organizations/${orgId}/users`, dto)
      .then((res) => plainToClass(OrganizationUserDto, res.data));
  },

  async updateOrganizationUser(
    orgId: string,
    id: string,
    role: OrganizationRoleDto
  ): Promise<OrganizationUserDto> {
    return apiClient
      .put<OrganizationUserDto>(`/organizations/${orgId}/users/${id}`, { role })
      .then((res) => plainToClass(OrganizationUserDto, res.data));
  },

  async deleteOrganizationUser(orgId: string, id: string): Promise<void> {
    return apiClient
      .delete<void>(`/organizations/${orgId}/users/${id}`)
      .then(() => {
        // void
      });
  },
};
