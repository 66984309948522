import { Box, Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

export interface PlaceholderLayoutProps {
  label?: string;
  link?: ReactNode;
}

export function PlaceholderLayout({
  label = 'Placeholder Page',
  link,
}: PlaceholderLayoutProps) {
  return (
    <Flex justify="center" align="center" direction="column" h="full">
      <h1>{label}</h1>
      {link && <Box mt="10">{link}</Box>}
    </Flex>
  );
}

export default PlaceholderLayout;
