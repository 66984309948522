import {
  Box,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverAnchor,
  PopoverCloseButton,
  PopoverContent,
  useBoolean,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { format } from 'date-fns';
import DatePicker from 'sassy-datepicker';

interface DatePickerInputProps {
  value: Date;
  onChange: (date: string) => void;
}

export function DatePickerInput({ value, onChange }: DatePickerInputProps) {
  const [showDatePicker, setDatePicker] = useBoolean();
  const [date, setDate] = useState(value);
  return (
    <Box>
      <Popover isOpen={showDatePicker} onClose={setDatePicker.off} closeOnBlur>
        <PopoverAnchor>
          <InputGroup>
            <InputLeftAddon children={<FiCalendar />} />
            <Input
              placeholder="Date"
              value={format(date, 'PP')}
              onClick={setDatePicker.on}
              isReadOnly
            />
          </InputGroup>
        </PopoverAnchor>
        <PopoverContent style={{ overflow: 'unset' }} w="full">
          <Box pt={8} borderTopRadius="lg" color="gray.50" bgColor="blue.500">
            <PopoverCloseButton />
          </Box>
          <DatePicker
            value={date}
            onChange={(val) => {
              setDate(val);
              setDatePicker.off();
              onChange(val.toISOString().split('T')[0]);
            }}
            style={{ border: 0, boxShadow: 'none' }}
          />
        </PopoverContent>
      </Popover>
    </Box>
  );
}
