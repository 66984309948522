import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Popover,
  PopoverAnchor,
  PopoverCloseButton,
  PopoverContent,
  useBoolean,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FiClock } from 'react-icons/fi';
import { TimePicker } from 'sassy-datepicker';

function convertTimeToString({
  hours,
  minutes,
}: {
  hours: number;
  minutes: number;
}) {
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
}

interface TimePickerInputProps {
  value: { hours: number; minutes: number };
  onChange: (date: string) => void;
}

export function TimePickerInput({ value, onChange }: TimePickerInputProps) {
  const [showTimePicker, setTimePicker] = useBoolean();
  const [time, setTime] = useState(value);
  const [updatedTime, setUpdatedTime] = useState(time);

  return (
    <Box>
      <Popover
        isOpen={showTimePicker}
        onClose={() => {
          setTimePicker.off();
        }}
      >
        <PopoverAnchor>
          <InputGroup>
            <InputLeftAddon children={<FiClock />} />

            <Input
              isReadOnly
              placeholder="Time"
              value={convertTimeToString(time)}
              onClick={setTimePicker.on}
            />
          </InputGroup>
        </PopoverAnchor>
        <PopoverContent style={{ overflow: 'unset' }} w="full">
          <Box pt={8} borderTopRadius="lg" color="gray.50" bgColor="blue.500">
            <PopoverCloseButton />
          </Box>
          <HStack p={2}>
            {showTimePicker && (
              <TimePicker
                style={{ border: 0 }}
                minutesInterval={1}
                value={time}
                onChange={(time) => {
                  setUpdatedTime(time);
                }}
              />
            )}
            <Button
              variant="solid"
              onClick={() => {
                setTime(updatedTime);
                onChange(convertTimeToString(updatedTime));
                setTimePicker.off();
              }}
            >
              Save
            </Button>
          </HStack>
        </PopoverContent>
      </Popover>
    </Box>
  );
}
