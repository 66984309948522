interface ApiClientConfig {
  baseUrl: string;
}

const config: ApiClientConfig = {
  baseUrl: '',
};
Object.seal(config);

export const ApiClientConfig = config;
