import { AutoMap } from '@automapper/classes';
import { IsBoolean, IsDate, IsOptional, IsString } from 'class-validator';
import { Type } from 'class-transformer';
import { CreateStripeIntegrationDto } from './create-stripe-integration.dto';

export class StripeIntegrationDto extends CreateStripeIntegrationDto {
  @AutoMap()
  @IsString()
  id!: string;

  @AutoMap()
  @IsBoolean()
  enabled!: boolean;

  @AutoMap()
  @IsString()
  orgId!: string;

  @AutoMap()
  @IsString()
  @IsOptional()
  programId?: string;

  @AutoMap()
  @IsDate()
  @Type(() => Date)
  installationDate?: Date;

  @AutoMap()
  @IsDate()
  @Type(() => Date)
  uninstallationDate?: Date;

  constructor(props?: StripeIntegrationDto) {
    super(props);
    Object.assign(this, props || {});
  }
}
