import { AutoMap } from '@automapper/classes';
import { CreateUserRegistrationProfileDto } from './create-user-registration-profile.dto';
import { ClassToPlainISODateFormatNoMillisUTCTimezone } from '@bookabl/shared/util';

export class UserRegistrationProfileDto extends CreateUserRegistrationProfileDto {
  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  createdAt!: Date;

  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  updatedAt!: Date;

  constructor(props?: UserRegistrationProfileDto) {
    super(props);
    if (!props) return;
    this.createdAt = props.createdAt;
    this.updatedAt = props.updatedAt;
  }
}
