import { theme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';

export const defaultTheme = extendTheme(
  {
    colors: {
      brand: theme['colors'].blue,
      'bookabl-blue': '#28b7ff',
      'bookabl-purple': '#8271ff',
    },
  },
  theme
);
