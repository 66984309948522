import { AutoMap } from '@automapper/classes';
import { OrganizationRoleDto } from './organization-role.dto';
import { IsEmail, IsEnum, IsNotEmpty } from 'class-validator';

export class CreateOrganizationUserDto {
  @AutoMap()
  @IsNotEmpty()
  @IsEmail()
  email!: string;

  @IsEnum(OrganizationRoleDto)
  @AutoMap({ typeFn: () => String })
  role!: OrganizationRoleDto;

  constructor(props?: CreateOrganizationUserDto) {
    Object.assign(this, props || {});
  }
}
