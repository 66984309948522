import {
  Container,
  Flex,
  Progress,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import AdminLayoutHeader from '../headers/admin-layout-header';
import useAdminSession from '../../../context/admin-session-context';
import useAuth from '../../../context/authentication-context';
import AdminSidebar from '../navigation/admin-sidebar/admin-sidebar';
import { usePageLoading } from '../../../context/page-loading-context';

export function AdminLayout() {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { currentOrganization } = useAdminSession();
  const { currentUser } = useAuth();
  const { isLoading } = usePageLoading();

  return (
    <Flex
      as="section"
      flexDir="column"
      h="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      <AdminLayoutHeader
        currentOrganization={currentOrganization}
        currentUser={currentUser}
      />
      {isLoading ? <Progress size="xs" isIndeterminate /> : <div />}
      <Flex as="section" flexDir={{ base: 'column', lg: 'row' }}>
        {isDesktop && (
          <AdminSidebar currentOrganization={currentOrganization} />
        )}
        <Container flex="1" py={8}>
          <Outlet />
        </Container>
      </Flex>
    </Flex>
  );
}

export default AdminLayout;
