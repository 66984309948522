import { Dictionary } from '@automapper/core/src/lib/types/utils';
import { CreateMapOptions } from '@automapper/core/src/lib/types/options';
import { CreateMapFluentFunction } from '@automapper/core/src/lib/types/core';
import '@automapper/core';

export * from './lib/decorator/class-to-plain-iso-date-format-no-millis-utc-timezone';
export * from './lib/util';

// Not entirely sure why this is required to be in the index.ts
// Haven't figured out how to pull it in its own file and build
declare module '@automapper/core' {
  interface Mapper {
    createMap<
      TSource extends Dictionary<TSource>,
      TDestination extends Dictionary<TDestination>
    >(
      source: new (args?: TSource) => TSource,
      destination: new (args?: TDestination) => TDestination,
      options?: CreateMapOptions
    ): CreateMapFluentFunction<TSource, TDestination>;
  }
}
