import { AutoMap } from '@automapper/classes';
import { IsString, Length, Matches, MaxLength } from 'class-validator';

export class CreateOrganizationDto {
  @AutoMap()
  @MaxLength(64)
  @IsString()
  name!: string;

  @AutoMap()
  @IsString()
  @Length(3, 36)
  @Matches(/^[a-zA-Z0-9][a-zA-Z0-9_-]{2,35}$/, {
    message:
      'publicId must start with a letter or number and only contain letter, numbers, underscores or dashes',
  })
  publicId!: string;

  constructor(props?: CreateOrganizationDto) {
    if (!props) return;

    this.name = props.name;
    this.publicId = props.publicId;
  }
}
