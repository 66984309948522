import { plainToClass } from 'class-transformer';
import { apiClient } from './api-client';
import {
  CroppedRectangleDto,
  MediaUploadSignatureDto,
  MediaUploadSignatureRequestDto,
} from '@bookabl/shared/model/integration';

export const MediaClient = {
  async generateSignature(
    dto: MediaUploadSignatureRequestDto
  ): Promise<MediaUploadSignatureDto> {
    return apiClient
      .post<MediaUploadSignatureDto>(`/media/upload/signature`, dto)
      .then((res) => plainToClass(MediaUploadSignatureDto, res.data));
  },

  async upload(
    file: File,
    signature: string,
    crop?: CroppedRectangleDto
  ): Promise<{ path: string }> {
    const formData = new FormData();
    formData.append('signature', signature);
    formData.append('file', file);
    if (crop) {
      if (crop.x != null) {
        formData.append('cropX', `${crop.x}`);
      }

      if (crop.y != null) {
        formData.append('cropY', `${crop.y}`);
      }

      if (crop.width != null) {
        formData.append('cropWidth', `${crop.width}`);
      }

      if (crop.height != null) {
        formData.append('cropHeight', `${crop.height}`);
      }

      if (crop.finalHeight != null) {
        formData.append('cropFinalHeight', `${crop.finalHeight}`);
      }

      if (crop.finalWidth != null) {
        formData.append('cropFinalWidth', `${crop.finalWidth}`);
      }
    }
    return apiClient
      .post<{ path: string }>(`/media/upload`, formData)
      .then((res) => {
        return res.data;
      });
  },
};
