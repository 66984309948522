import { AutoMap } from '@automapper/classes';
import { Type } from 'class-transformer';
import { UserOrganizationDto } from './user-organization.dto';

export class UserOrganizationAccessDto {
  @AutoMap()
  userId!: string;

  @AutoMap({ typeFn: () => UserOrganizationDto })
  @Type(() => UserOrganizationDto)
  organizations!: UserOrganizationDto[];
}
