import { AutoMap } from '@automapper/classes';
import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class CreateSponsorDto {
  @AutoMap()
  @IsString()
  name!: string;

  @AutoMap()
  @IsBoolean()
  published!: boolean;

  @AutoMap()
  @IsString()
  @IsOptional()
  imageUrl?: string;

  @AutoMap()
  @IsString()
  @IsOptional()
  url?: string;

  constructor(props?: CreateSponsorDto) {
    Object.assign(this, props || {});
  }
}
