import { As, Button, ButtonProps, HStack, Icon, Text } from '@chakra-ui/react';
import { Link, To, useMatch, useResolvedPath } from 'react-router-dom';

interface NavButtonWithLinkProps extends NavButtonProps {
  to: To;
  exactMatch?: boolean;
}

function NavButtonWithLink({
  icon,
  label,
  to,
  exactMatch = true,
  ...buttonProps
}: NavButtonWithLinkProps) {
  const resolvedPath = useResolvedPath(to);
  const match = useMatch(resolvedPath.pathname + `${exactMatch ? '' : '/*'}`);

  return (
    <Button
      variant="ghost"
      justifyContent="start"
      as={Link}
      to={to}
      aria-current={match ? 'page' : undefined}
      {...buttonProps}
    >
      <HStack spacing="2">
        <Icon as={icon} boxSize="6" color="subtle" />
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
}

export interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  to?: To;
  exactMatch?: boolean;
}

export function NavButton({
  icon,
  label,
  to,
  exactMatch,
  ...buttonProps
}: NavButtonProps) {
  if (to) {
    return (
      <NavButtonWithLink
        icon={icon}
        label={label}
        to={to}
        exactMatch={exactMatch}
      />
    );
  }

  return (
    <Button variant="ghost" justifyContent="start" {...buttonProps}>
      <HStack spacing="2">
        <Icon as={icon} boxSize="6" color="subtle" />
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
}

export default NavButton;
