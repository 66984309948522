import { AutoMap } from '@automapper/classes';
import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsString,
  MaxLength,
  ValidateNested,
} from 'class-validator';
import type { EventTypeDto } from './event-type.dto';
import { Type } from 'class-transformer';
import { CreateEventOccurrenceDto } from './create-event-occurrence.dto';

export class CreateEventDto {
  @AutoMap()
  @IsString()
  orgId!: string;

  @AutoMap()
  @IsString()
  programId!: string;

  @AutoMap()
  @IsString()
  name!: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  @MaxLength(500)
  summary?: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  slug?: string;

  @AutoMap({ typeFn: () => String })
  type!: EventTypeDto;

  @ValidateNested()
  @IsArray()
  @IsNotEmpty()
  @AutoMap({ typeFn: () => CreateEventOccurrenceDto })
  @Type(() => CreateEventOccurrenceDto)
  occurrences!: CreateEventOccurrenceDto[];

  @IsBoolean()
  @AutoMap()
  published!: boolean;

  constructor();
  constructor(props?: CreateEventDto) {
    Object.assign(this, props || {});
  }
}
