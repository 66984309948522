import { plainToClass } from 'class-transformer';
import {
  CurrentUserDto,
  UserOrganizationAccessDto,
  UserProgramAccessDto,
} from '@bookabl/shared/model/identity';
import { apiClient } from './api-client';

export const MeService = {
  async getCurrentUser(): Promise<CurrentUserDto> {
    return apiClient
      .get<CurrentUserDto>(`/me`)
      .then((res) => plainToClass(CurrentUserDto, res.data));
  },
  async getOrganizationsForCurrentUser(): Promise<UserOrganizationAccessDto> {
    return apiClient
      .get<UserOrganizationAccessDto>(`/me/organizations`)
      .then((res) => plainToClass(UserOrganizationAccessDto, res.data));
  },
  async getProgramsForCurrentUser(
    orgId: string
  ): Promise<UserProgramAccessDto> {
    return apiClient
      .get<UserProgramAccessDto>(`/me/organizations/${orgId}/programs`)
      .then((res) => plainToClass(UserProgramAccessDto, res.data));
  },
};
