import {
  AuthenticationResponseDto,
  CredentialDto,
} from '@bookabl/shared/model/authentication';
import { plainToClass } from 'class-transformer';
import { apiClient } from './api-client';

export const AuthenticationService = {
  authenticate(credentials: CredentialDto): Promise<AuthenticationResponseDto> {
    return apiClient
      .post<AuthenticationResponseDto>('/auth/sessions', credentials)
      .then((res) => plainToClass(AuthenticationResponseDto, res.data));
  },

  logout(): Promise<void> {
    return apiClient.delete('/auth/sessions').then(() => {
      // do nothing
    });
  },
};
