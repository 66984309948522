import {
  emptyOrganization,
  OrganizationDto,
  OrganizationRoleDto,
} from '@bookabl/shared/model/organization';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { useMeGetOrganizationsForCurrentUser } from '@bookabl/client/api-client';

const defaultOrganization = emptyOrganization();

interface AdminSessionContextProps {
  currentOrganization: OrganizationDto;
  currentRole: OrganizationRoleDto;
}

const AdminSessionContext = createContext<AdminSessionContextProps>({
  currentOrganization: defaultOrganization,
  currentRole: OrganizationRoleDto.OrgProgramAccess,
});

export function AdminSessionProvider({ children }: { children: ReactNode }) {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const isOauthInstall = useMatch('/admin/oauth/install/*');
  const { data: userOrgs } = useMeGetOrganizationsForCurrentUser();

  const stateMemo = useMemo(() => {
    const userOrg = userOrgs?.organizations[0];
    const currentOrganization = userOrg?.organization || defaultOrganization;
    const currentRole = userOrg?.role || OrganizationRoleDto.OrgProgramAccess;
    return {
      orgId,
      currentOrganization,
      currentRole,
    };
  }, [orgId, userOrgs]);

  useEffect(() => {
    const currentOrganization = stateMemo.currentOrganization;

    if (
      isOauthInstall == null &&
      currentOrganization &&
      currentOrganization.id &&
      orgId !== currentOrganization.id
    ) {
      navigate(`orgs/${currentOrganization.id}`, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, stateMemo.currentOrganization]);

  return (
    <AdminSessionContext.Provider value={stateMemo}>
      {userOrgs && children}
    </AdminSessionContext.Provider>
  );
}

export default function useAdminSession(): AdminSessionContextProps {
  return useContext(AdminSessionContext);
}
