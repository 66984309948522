import {
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
  Length,
  Matches,
  MaxLength,
  MinLength,
} from 'class-validator';
import { AutoMap } from '@automapper/classes';

export class CreateUserDto {
  @AutoMap()
  @IsString()
  name!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsEmail()
  email!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsString()
  @MinLength(8)
  @MaxLength(64)
  password!: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  @Length(11, 15)
  @Matches(/^[0-9]{11,15}$/, {
    message:
      'mobilePhone must be all digits minimum 11 characters. (eg. 1555888888)',
  })
  mobilePhone?: string;

  constructor(props?: CreateUserDto) {
    Object.assign(this, props || {});
  }
}
