import { AutoMap } from '@automapper/classes';
import { IsString } from 'class-validator';

export class MediaUploadSignatureDto {
  @AutoMap()
  @IsString()
  signature!: string;

  constructor(props?: MediaUploadSignatureDto) {
    Object.assign(this, props || {});
  }
}
