import { Link, Route, Routes, useParams } from 'react-router-dom';
import AdminOrganizationSidebar from './admin-organization-sidebar';
import {
  OrganizationDto,
  OrganizationRoleDto,
} from '@bookabl/shared/model/organization';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useAdminSession from '../../../../context/admin-session-context';
import { useMeGetProgramsForCurrentUser } from '@bookabl/client/api-client';
import {
  organizationRoute,
  programRoute,
} from '../../../../utils/routing-utils';
import AdminProgramSidebar from './admin-program-sidebar';
import { UserProgramAccessDto } from '@bookabl/shared/model/identity';
import { CurrentProgramProvider } from '../../../../context/current-program-context';
import EventSidebar from '../../../../features/event/components/event-sidebar';

interface ProgramSelectorProps {
  currentOrganization: OrganizationDto;
}

function determineSelectedMenu(
  orgRole: OrganizationRoleDto,
  programAccess?: UserProgramAccessDto,
  programId?: string
) {
  if (!programAccess || programAccess.programs.length === 0) {
    return;
  }

  let selectedProgram = programAccess.programs[0].program;
  if (programId) {
    selectedProgram =
      programAccess.programs.find((p) => p.program.id === programId)?.program ||
      selectedProgram;
  }

  let menuLabel = selectedProgram.name;
  let menuValue = selectedProgram.id;
  if (!programId && orgRole !== OrganizationRoleDto.OrgProgramAccess) {
    menuLabel = 'All Programs';
    menuValue = 'all';
  }

  return {
    menuLabel,
    menuValue,
  };
}

function ProgramSelector({ currentOrganization }: ProgramSelectorProps) {
  const { currentRole } = useAdminSession();
  const { programId } = useParams() as { programId: string };
  const { data: userPrograms } = useMeGetProgramsForCurrentUser(
    currentOrganization.id
  );
  const [selectedMenu, setSelectedMenu] = useState<{
    menuLabel: string;
    menuValue: string;
  }>();

  useEffect(() => {
    const selectedMenu = determineSelectedMenu(
      currentRole,
      userPrograms,
      programId
    );
    setSelectedMenu(selectedMenu);
  }, [currentRole, programId, userPrograms]);

  if (!selectedMenu) {
    return <div />;
  }

  return (
    <Menu>
      <MenuButton as={Button} w="full" minW="224px">
        {selectedMenu.menuLabel}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup defaultValue={selectedMenu.menuValue} type="radio">
          {currentRole !== OrganizationRoleDto.OrgProgramAccess && (
            <MenuItemOption
              onClick={() =>
                setSelectedMenu({ menuLabel: 'All Programs', menuValue: 'all' })
              }
              as={Link}
              to={organizationRoute(currentOrganization.id)}
              value="all"
            >
              All Programs
            </MenuItemOption>
          )}
          {currentRole !== OrganizationRoleDto.OrgProgramAccess && (
            <MenuDivider />
          )}
          {userPrograms &&
            userPrograms.programs.map((program) => {
              return (
                <MenuItemOption
                  key={program.program.id}
                  onClick={() =>
                    setSelectedMenu({
                      menuLabel: program.program.name,
                      menuValue: program.program.id,
                    })
                  }
                  as={Link}
                  to={programRoute(currentOrganization.id, program.program.id)}
                  value={program.program.id}
                >
                  {program.program.name}
                </MenuItemOption>
              );
            })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

export interface AdminSidebarProps {
  currentOrganization: OrganizationDto;
  scrollable?: boolean;
}

export function AdminSidebar({
  currentOrganization,
  scrollable,
}: AdminSidebarProps) {
  return (
    <Flex
      as="section"
      flexDir="column"
      bg="bg-canvas"
      overflowY={scrollable ? 'auto' : undefined}
    >
      <Flex
        flex="1"
        minH={`calc(100vh - ${scrollable ? '0' : '72'}px)`}
        bg="bg-surface"
        boxShadow="sm"
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
      >
        <Stack spacing={6} w="full">
          <Flex flex="1">
            <Routes>
              <Route
                path="/orgs/:orgId/programs/:programId/events/:eventId/*"
                element={<EventSidebar />}
              />
              <Route
                path="/orgs/:orgId/programs/:programId/*"
                element={
                  <CurrentProgramProvider>
                    <AdminProgramSidebar
                      programSelector={
                        <Box>
                          <ProgramSelector
                            currentOrganization={currentOrganization}
                          />
                        </Box>
                      }
                      currentOrganization={currentOrganization}
                    />
                  </CurrentProgramProvider>
                }
              />
              <Route
                path="/orgs/:orgId/*"
                element={
                  <AdminOrganizationSidebar
                    programSelector={
                      <Box>
                        <ProgramSelector
                          currentOrganization={currentOrganization}
                        />
                      </Box>
                    }
                    currentOrganization={currentOrganization}
                  />
                }
              />
            </Routes>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
}

export default AdminSidebar;
