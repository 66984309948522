import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface LayoutContextProps {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
  isLoading: false,
} as LayoutContextProps);

export function PageLoadingProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);

  const setLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  const stateMemo = useMemo(
    () => ({
      isLoading,
      setLoading,
    }),
    [isLoading]
  );
  return (
    <LayoutContext.Provider value={stateMemo}>
      {children}
    </LayoutContext.Provider>
  );
}

export function usePageLoading(): LayoutContextProps {
  return useContext(LayoutContext);
}
