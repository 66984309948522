import {
  Flex,
  chakra,
  Stack,
  Text,
  HStack,
  Button,
  Box,
} from '@chakra-ui/react';

/* eslint-disable-next-line */
export interface EventPublishSectionProps {
  onSave: () => void;
}

export function EventPublishSection({ onSave }: EventPublishSectionProps) {
  return (
    <chakra.form h="full">
      <Flex flexDir="column" h="full">
        <Flex flexDir="column" flex="1" h="full" justifyContent="space-between">
          <Stack direction="row" spacing={10}>
            <Stack spacing={10} w="container.md">
              <Box>
                <Text fontSize="3xl">Publish your event</Text>
                <Box mt={4} rounded="lg" bg="bg-surface" p={5}>
                  Placeholder for event tile
                </Box>
              </Box>
            </Stack>
          </Stack>
          <Flex w="full" justifyContent="end" pt={10}>
            <HStack spacing={5}>
              <Button variant="ghost" px={10}>
                Cancel
              </Button>
              <Button variant="outline" px={10} onClick={onSave}>
                Save as draft
              </Button>
              <Button colorScheme="blue" px={10} onClick={onSave}>
                Save & Publish
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
    </chakra.form>
  );
}

export default EventPublishSection;
