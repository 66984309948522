import { EventDto } from '@bookabl/shared/model/event';
import { useQuery } from 'react-query';
import { EventClient } from './event-client';

export function useEventsForProgram(
  orgId: string,
  programId: string,
  initialData?: EventDto[]
) {
  return useQuery<EventDto[]>(
    ['getEventsForProgram', orgId, programId],
    () => EventClient.getEventsForProgram(orgId, programId),
    { enabled: !!(orgId && programId), initialData }
  );
}
