import { ReactNode } from 'react';

export interface DefaultLayoutProps {
  children?: ReactNode;
}

/*
export function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <header sx={{ p: 3 }}>bookabl</header>
      <main
        sx={{
          width: '100%',
          flex: '1 1 auto',
          p: 3,
        }}
      >
        {props.children}
      </main>
    </div>
  );
}
*/
export function DefaultLayout(props: DefaultLayoutProps) {
  return (
    <div>
      <header>bookabl</header>
      <main>{props.children}</main>
    </div>
  );
}

export default DefaultLayout;
