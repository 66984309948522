import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';

import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiImage, FiTrash, FiTrash2 } from 'react-icons/fi';
import { organizationRoute, programRoute } from '../../../utils/routing-utils';
import { PageContext } from '../../../model/page-context';
import useCurrentProgram from '../../../context/current-program-context';
import useAdminSession from '../../../context/admin-session-context';
import { useMutation } from 'react-query';
import {
  MediaClient,
  SponsorClient,
  useGetSponsorForProgram,
} from '@bookabl/client/api-client';
import { ProgramDto } from '@bookabl/shared/model/program';
import { OrganizationDto } from '@bookabl/shared/model/organization';
import { useEffect, useState } from 'react';
import { logger } from '@bookabl/client/util';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import UploadImageModal, {
  SelectedImage,
} from '../../../components/upload-image-modal';
import {
  CreateSponsorDto,
  UpdateSponsorDto,
} from '@bookabl/shared/model/content';
import { CroppedRectangleDto } from '@bookabl/shared/model/integration';
import { imageUrl } from '../../../utils/utils';

function contextRoute(
  pageContext: PageContext,
  orgId: string,
  programId: string | undefined,
  path: string
) {
  return pageContext === PageContext.Organization
    ? organizationRoute(orgId, path)
    : programRoute(orgId, programId || '', path);
}

interface DeleteSponsorButtonProps {
  pageContext: PageContext;
  currentOrganization: OrganizationDto;
  currentProgram: ProgramDto;
  sponsorId: string;
  isLoading?: boolean;
}

function DeleteSponsorButton({
  pageContext,
  currentOrganization,
  currentProgram,
  sponsorId,
  isLoading,
}: DeleteSponsorButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteSponsor = useMutation(() =>
    pageContext === PageContext.Organization
      ? SponsorClient.deleteSponsorForOrganization()
      : SponsorClient.deleteSponsorForProgram(
          currentProgram.orgId,
          currentProgram.id,
          sponsorId
        )
  );
  const toast = useToast();
  const navigate = useNavigate();

  const confirmDelete = async () => {
    deleteSponsor.mutate(undefined, {
      onSuccess: () => {
        navigate(
          contextRoute(
            pageContext,
            currentOrganization.id,
            currentProgram.id,
            '/sponsors'
          )
        );
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'There was an error removing the integration',
        });
        logger.error(`Error removing sponsor`, deleteSponsor.error);
      },
    });
  };

  return (
    <>
      <Button
        colorScheme="red"
        leftIcon={<FiTrash2 />}
        isLoading={isLoading || deleteSponsor.isLoading}
        onClick={onOpen}
      >
        Delete
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to remove this sponsor?</ModalBody>
          <ModalFooter>
            <HStack>
              <Button
                variant="ghost"
                onClick={onClose}
                isDisabled={deleteSponsor.isLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                isLoading={deleteSponsor.isLoading}
                onClick={confirmDelete}
              >
                Yes, remove
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

const formSchema = yup.object({
  name: yup.string().required('Name is required'),
  published: yup.boolean().required('Published is required'),
  url: yup.string().url('URL format is required'),
});

interface EditSponsorForm {
  name: string;
  published: boolean;
  url?: string;
  imageUrl?: string;
}

export interface EditSponsorPageProps {
  pageContext: PageContext;
}

export function EditSponsorPage({ pageContext }: EditSponsorPageProps) {
  const { id } = useParams() as { id: string };
  const isCreate = id === 'new';
  const { currentOrganization } = useAdminSession();
  const { currentProgram } = useCurrentProgram();
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState<SelectedImage>();
  const [logoUrl, setLogoUrl] = useState<string>();

  const { data: sponsor, isLoading } = useGetSponsorForProgram(
    currentOrganization.id,
    currentProgram.id,
    id,
    !isCreate
  );

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm<EditSponsorForm>({
    resolver: yupResolver(formSchema),
    mode: 'onBlur',
    defaultValues: {
      published: false,
    },
  });

  const watchedPublished = watch('published');

  const createSponsor = useMutation((dto: CreateSponsorDto) =>
    SponsorClient.createSponsorForProgram(
      currentOrganization.id,
      currentProgram.id,
      dto
    )
  );

  const updateSponsor = useMutation((dto: UpdateSponsorDto) =>
    SponsorClient.updateSponsorForProgram(
      currentOrganization.id,
      currentProgram.id,
      id,
      dto
    )
  );

  const uploadMedia = useMutation(
    ({
      sponsorId,
      croppedRectangle,
      key,
    }: {
      sponsorId: string;
      croppedRectangle?: CroppedRectangleDto;
      key: string;
    }) => {
      if (!selectedImage) {
        return Promise.reject();
      }

      return MediaClient.generateSignature({ key }).then(({ signature }) =>
        MediaClient.upload(
          selectedImage.imageFile,
          signature,
          croppedRectangle
        ).then((uploadRes) =>
          SponsorClient.setSponsorMediaForProgram(
            currentOrganization.id,
            currentProgram.id,
            sponsorId,
            uploadRes.path
          )
        )
      );
    }
  );

  useEffect(() => {
    if (!sponsor) {
      return;
    }

    if (!isDirty) {
      if (sponsor.imageUrl) {
        setLogoUrl(imageUrl(sponsor.imageUrl));
      }

      reset({
        name: sponsor.name,
        published: sponsor.published,
        url: sponsor.url,
        imageUrl: sponsor.imageUrl,
      });
    }
  }, [sponsor, isDirty, reset]);

  const onImageSelected = (selectedImage: SelectedImage) => {
    setLogoUrl(selectedImage.croppedImageDataUrl);
    setSelectedImage(selectedImage);
  };

  const notifyOnSuccess = () => {
    toast({
      status: 'success',
      description: 'Sponsor saved successfully.',
    });
    navigate(
      contextRoute(
        pageContext,
        currentOrganization.id,
        currentProgram.id,
        '/sponsors'
      )
    );
  };

  const tryUploadImageAndNotifySuccess = ({
    id: sponsorId,
  }: {
    id: string;
  }) => {
    if (selectedImage) {
      let croppedRectangle: CroppedRectangleDto | undefined = undefined;
      if (selectedImage.croppedRectangle) {
        croppedRectangle = {
          ...selectedImage.croppedRectangle,
          finalHeight: 250,
          finalWidth: 250,
        };
      }

      const key = `/orgs/${currentOrganization.id}/programs/${currentProgram.id}/media/sponsors`;
      uploadMedia.mutate(
        { sponsorId, key, croppedRectangle },
        { onSuccess: notifyOnSuccess, onError: notifyOnSuccess }
      );
    } else {
      notifyOnSuccess();
    }
  };

  const onSubmit: SubmitHandler<EditSponsorForm> = async (data) => {
    if (isCreate) {
      await createSponsor.mutate(data, {
        onSuccess: tryUploadImageAndNotifySuccess,
        onError: () => {
          toast({
            status: 'error',
            description: 'There was an error creating the sponsor.',
          });
        },
      });
    } else {
      await updateSponsor.mutate(data, {
        onSuccess: tryUploadImageAndNotifySuccess,
        onError: () => {
          toast({
            status: 'error',
            description: 'There was an error updating the sponsor.',
          });
        },
      });
    }
  };

  return (
    <AdminPageLayout
      title="Manage sponsor"
      description="Update sponsor details"
      isLoading={isLoading}
    >
      <chakra.form h="full" onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir="column" h="full" justifyContent="space-between">
          <Stack w="container.sm" spacing={4}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.url}>
              <FormLabel>URL</FormLabel>
              <Input {...register('url')} />
              <FormErrorMessage>{errors.url?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.published}>
              <HStack>
                <Switch size="lg" {...register('published')} />
                <FormLabel>
                  {watchedPublished ? 'Published' : 'Unpublished'}
                </FormLabel>
              </HStack>
              <FormErrorMessage>{errors.published?.message}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Image</FormLabel>
              {logoUrl ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  w="250px"
                  h="250px"
                  border="2px dashed"
                  borderColor="gray.200"
                  rounded="lg"
                >
                  <Image rounded="lg" src={logoUrl} w="full" h="full" />
                  <HStack
                    rounded="lg"
                    _hover={{ visibility: 'visible', opacity: 0.9 }}
                    opacity={0}
                    spacing={40}
                    position="absolute"
                    bgColor="gray.200"
                    px={3}
                    py={3}
                  >
                    {/*
                        <IconButton
                          variant="outline"
                          size="xl"
                          aria-label="Crop image"
                          icon={<FiCrop />}
                          onClick={() => {
                            console.log(selectedImage);
                          }}
                        />
                        */}
                    <IconButton
                      variant="outline"
                      size="xl"
                      aria-label="Remove image"
                      icon={<FiTrash />}
                      onClick={() => {
                        setValue('imageUrl', '', { shouldDirty: true });
                        setLogoUrl(undefined);
                      }}
                    />
                  </HStack>
                </Flex>
              ) : (
                <Flex
                  w="250px"
                  h="250px"
                  justifyContent="center"
                  alignItems="center"
                  rounded="lg"
                  border="2px dashed"
                  borderColor="gray.200"
                >
                  <VStack>
                    <Icon color="gray.200" as={FiImage} boxSize={20} />
                    <UploadImageModal
                      onImageSelected={onImageSelected}
                      editor={{ height: 250, width: 250 }}
                    />
                  </VStack>
                </Flex>
              )}
            </FormControl>
          </Stack>
          <HStack
            borderTop="1px solid"
            borderColor="gray.200"
            justifyContent="space-between"
            mt={6}
            pt={6}
          >
            <Box>
              {!isCreate && (
                <DeleteSponsorButton
                  pageContext={pageContext}
                  currentOrganization={currentOrganization}
                  currentProgram={currentProgram}
                  sponsorId={id}
                  isLoading={createSponsor.isLoading || uploadMedia.isLoading}
                />
              )}
            </Box>
            <HStack spacing={4}>
              <Button
                isLoading={createSponsor.isLoading || uploadMedia.isLoading}
                variant="outline"
                as={Link}
                to={contextRoute(
                  pageContext,
                  currentOrganization.id,
                  currentProgram.id,
                  '/sponsors'
                )}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={createSponsor.isLoading || uploadMedia.isLoading}
              >
                Save changes
              </Button>
            </HStack>
          </HStack>
        </Flex>
      </chakra.form>
    </AdminPageLayout>
  );
}

export default EditSponsorPage;
