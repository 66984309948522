import { AutoMap } from '@automapper/classes';
import { CreateRegistrationProfileDto } from './create-registration-profile.dto';

export class RegistrationProfileDto extends CreateRegistrationProfileDto {
  @AutoMap()
  id!: string;

  constructor(props?: RegistrationProfileDto) {
    super(props);
    if (!props) return;
    this.id = props.id;
  }
}
