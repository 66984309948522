import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiImage, FiTrash } from 'react-icons/fi';
import UploadImageModal, {
  SelectedImage,
} from '../../../../../components/upload-image-modal';
import { useEffect, useState } from 'react';
import {
  useGetHostsForProgram,
  useGetSponsorsForProgram,
} from '@bookabl/client/api-client';
import { EventHostDto } from '@bookabl/shared/model/event';
import useCurrentProgram from '../../../../../context/current-program-context';
import { imageUrl } from '../../../../../utils/utils';
import { SponsorDto } from '@bookabl/shared/model/content';
import { useForm } from 'react-hook-form';

interface SponsorListProps {
  data?: SponsorDto[];
}

export function SponsorList({ data }: SponsorListProps) {
  const [selectedSponsor, setSelectedSponsor] = useState<string>('');
  const [allSponsors, setAllSponsors] = useState<SponsorDto[]>(data || []);
  const [sponsors, setSponsors] = useState<SponsorDto[]>([]);

  useEffect(() => {
    if (data) {
      setAllSponsors(data);
    }
  }, [data]);

  if (!data) {
    return <div />;
  }

  return (
    <Box>
      <Text fontSize="3xl">Sponsors</Text>
      <Text mb={4}>Add the sponsor(s) of your event</Text>
      <Box rounded="lg" bg="bg-surface" p={5}>
        <HStack spacing={5}>
          <Select
            placeholder="Select a sponsor"
            onChange={(e) => {
              setSelectedSponsor(e.target.value);
            }}
            value={selectedSponsor}
          >
            {allSponsors.map((d) => (
              <option key={d.id} value={d.id}>
                {d.name}
              </option>
            ))}
          </Select>
          <Box>
            <Button
              onClick={() => {
                const sponsor = data?.find((d) => d.id === selectedSponsor);
                if (sponsor) {
                  setSponsors([...sponsors, sponsor]);
                  setAllSponsors(
                    allSponsors.filter((s) => s.id !== sponsor.id)
                  );
                  setSelectedSponsor('');
                }
              }}
            >
              Add
            </Button>
          </Box>
        </HStack>
        <Stack mt={5}>
          {sponsors.length === 0 ? (
            <Alert status="info">
              <AlertIcon />
              <AlertDescription>No sponsors added</AlertDescription>
            </Alert>
          ) : (
            sponsors.map((s, idx) => (
              <Flex justifyContent="space-between" key={`eventHosts-${s.id}`}>
                <HStack spacing={4}>
                  <Avatar name={s.name} src={imageUrl(s.imageUrl)} />
                  <Text>{s.name}</Text>
                </HStack>
                <HStack spacing={2}>
                  {idx > 0 && (
                    <IconButton
                      aria-label="Move sponsor up"
                      variant="ghost"
                      icon={<FiChevronUp />}
                      onClick={() => {
                        const newHosts = [...sponsors];
                        newHosts[idx] = newHosts[idx - 1];
                        newHosts[idx - 1] = s;
                        setSponsors(newHosts);
                      }}
                    />
                  )}
                  {idx !== sponsors.length - 1 && (
                    <IconButton
                      aria-label="Move sponsor down"
                      variant="ghost"
                      icon={<FiChevronDown />}
                      onClick={() => {
                        const newHosts = [...sponsors];
                        newHosts[idx] = newHosts[idx + 1];
                        newHosts[idx + 1] = s;
                        setSponsors(newHosts);
                      }}
                    />
                  )}
                  <IconButton
                    aria-label="Remove host"
                    icon={<FiTrash />}
                    colorScheme="red"
                    onClick={() => {
                      setSponsors(
                        sponsors.filter((sponsor) => sponsor.id !== s.id)
                      );
                      setAllSponsors([...allSponsors, s]);
                    }}
                  />
                </HStack>
              </Flex>
            ))
          )}
        </Stack>
      </Box>
    </Box>
  );
}

interface HostListProps {
  data?: EventHostDto[];
}

export function HostList({ data }: HostListProps) {
  const [selectedHost, setSelectedHost] = useState<string>('');
  const [allHosts, setAllHosts] = useState<EventHostDto[]>(data || []);
  const [hosts, setHosts] = useState<EventHostDto[]>([]);

  useEffect(() => {
    if (data) {
      setAllHosts(data);
    }
  }, [data]);

  if (!data) {
    return <div />;
  }

  return (
    <Box>
      <Text fontSize="3xl">Hosts</Text>
      <Text mb={4}>Add the host(s) of your event</Text>
      <Box rounded="lg" bg="bg-surface" p={5}>
        <HStack spacing={5}>
          <Select
            placeholder="Select a host"
            onChange={(e) => {
              setSelectedHost(e.target.value);
            }}
            value={selectedHost}
          >
            {allHosts.map((d) => (
              <option key={d.id} value={d.id}>
                {d.name}
              </option>
            ))}
          </Select>
          <Box>
            <Button
              onClick={() => {
                const host = data?.find((d) => d.id === selectedHost);
                if (host) {
                  setHosts([...hosts, host]);
                  setAllHosts(allHosts.filter((h) => h.id !== host.id));
                  setSelectedHost('');
                }
              }}
            >
              Add
            </Button>
          </Box>
        </HStack>
        <Stack mt={5}>
          {hosts.length === 0 ? (
            <Alert status="info">
              <AlertIcon />
              <AlertDescription>No hosts added</AlertDescription>
            </Alert>
          ) : (
            hosts.map((h, idx) => (
              <Flex justifyContent="space-between" key={`eventHosts-${h.id}`}>
                <HStack spacing={4}>
                  <Avatar name={h.name} src={imageUrl(h.profileImageUrl)} />
                  <Text>{h.name}</Text>
                </HStack>
                <HStack spacing={2}>
                  {idx > 0 && (
                    <IconButton
                      aria-label="Move host up"
                      variant="ghost"
                      icon={<FiChevronUp />}
                      onClick={() => {
                        const newHosts = [...hosts];
                        newHosts[idx] = newHosts[idx - 1];
                        newHosts[idx - 1] = h;
                        setHosts(newHosts);
                      }}
                    />
                  )}
                  {idx !== hosts.length - 1 && (
                    <IconButton
                      aria-label="Move host down"
                      variant="ghost"
                      icon={<FiChevronDown />}
                      onClick={() => {
                        const newHosts = [...hosts];
                        newHosts[idx] = newHosts[idx + 1];
                        newHosts[idx + 1] = h;
                        setHosts(newHosts);
                      }}
                    />
                  )}
                  <IconButton
                    aria-label="Remove host"
                    icon={<FiTrash />}
                    colorScheme="red"
                    onClick={() => {
                      setHosts(hosts.filter((host) => host.id !== h.id));
                      setAllHosts([...allHosts, h]);
                    }}
                  />
                </HStack>
              </Flex>
            ))
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export interface EventGeneralInfoSectionProps {
  isCreate: boolean;
  onNext: (data: any) => void;
}

export function EventDetailsSection({
  isCreate,
  onNext,
}: EventGeneralInfoSectionProps) {
  const { currentProgram } = useCurrentProgram();

  const { handleSubmit } = useForm();

  const [selectedImage, setSelectedImage] = useState<SelectedImage>();
  const [logoUrl, setLogoUrl] = useState<string>();

  const { data: hosts } = useGetHostsForProgram(
    currentProgram.orgId,
    currentProgram.id,
    true
  );

  const { data: sponsors } = useGetSponsorsForProgram(
    currentProgram.orgId,
    currentProgram.id,
    true
  );

  const onImageSelected = (imageSelected: SelectedImage) => {
    setLogoUrl(imageSelected.croppedImageDataUrl);
    setSelectedImage(imageSelected);
  };

  return (
    <chakra.form
      onSubmit={handleSubmit(() => {
        onNext('');
      })}
    >
      <Flex flexDir="column" h="full">
        <Flex flexDir="column" flex="1" h="full" justifyContent="space-between">
          <Stack direction="row" spacing={10}>
            <Stack spacing={10} w="container.md">
              <Box>
                <Text fontSize="3xl">Main event image</Text>
                <Text mb={4}>This is the main image for your event</Text>
                <Box rounded="lg" bg="bg-surface" p={5}>
                  {logoUrl ? (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      onMouseOver={() => console.log('over')}
                      position="relative"
                    >
                      <Image src={logoUrl} w="full" maxH="50%" />
                      <HStack
                        rounded="lg"
                        _hover={{ visibility: 'visible', opacity: 0.9 }}
                        opacity={0}
                        spacing={40}
                        position="absolute"
                        bgColor="gray.200"
                        px={3}
                        py={3}
                      >
                        {/*
                        <IconButton
                          variant="outline"
                          size="xl"
                          aria-label="Crop image"
                          icon={<FiCrop />}
                          onClick={() => {
                            console.log(selectedImage);
                          }}
                        />
                        */}
                        <IconButton
                          variant="outline"
                          size="xl"
                          aria-label="Remove image"
                          icon={<FiTrash />}
                          onClick={() => setLogoUrl(undefined)}
                        />
                      </HStack>
                    </Flex>
                  ) : (
                    <Flex
                      w="full"
                      h="364px"
                      justifyContent="center"
                      alignItems="center"
                      rounded="lg"
                      border="2px dashed"
                      borderColor="gray.200"
                    >
                      <VStack>
                        <Icon color="gray.200" as={FiImage} boxSize={20} />
                        <UploadImageModal
                          onImageSelected={onImageSelected}
                          editor={{ height: 250, width: 500 }}
                        />
                      </VStack>
                    </Flex>
                  )}
                </Box>
              </Box>
              <HostList data={hosts?.data} />
              <SponsorList data={sponsors?.data} />
            </Stack>
            <Box>
              <Image src="/assets/images/astro1.svg" />
            </Box>
          </Stack>
          <Flex w="full" justifyContent="end" pt={10}>
            <HStack spacing={10}>
              <Button variant="ghost">Cancel</Button>
              <Button colorScheme="blue" w={160} type="submit">
                Next
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
    </chakra.form>
  );
}

export default EventDetailsSection;
