import { IsString } from 'class-validator';
import { AutoMap } from '@automapper/classes';
import { ClassToPlainISODateFormatNoMillisUTCTimezone } from '@bookabl/shared/util';
import { CreateUserDto } from './create-user.dto';

export class UserDto extends CreateUserDto {
  @AutoMap()
  @IsString()
  id!: string;

  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  createdAt!: Date;

  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  updatedAt!: Date;

  constructor(props?: UserDto) {
    super(props);
    Object.assign(this, props || {});
  }
}
