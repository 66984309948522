import {
  Box,
  Center,
  chakra,
  Flex,
  Heading,
  HStack,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { BsDot } from 'react-icons/bs';
import { FaHeart } from 'react-icons/fa';
import { ReactComponent as Logo } from '../../../../assets/logos/bookabl_event_logo_dark_png.svg';
import {
  PRIVACY_POLICY_URL,
  SUPPORT_URL,
  TERMS_OF_USE_URL,
} from '../../../utils/constants';
import SignInForm from '../components/sign-in-form';

const ChakraLogo = chakra(Logo);

export function SignInPage() {
  return (
    <Flex
      minH={{ base: 'auto', md: '100vh' }}
      bgGradient={{
        md: 'linear(to-r, bookabl-purple 0%, bookabl-blue 50%, white 50%)',
      }}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: 'none', md: 'block' }}>
          <Flex
            direction="column"
            px={{ base: '4', md: '8' }}
            height="full"
            color="white"
          >
            <Flex
              direction="column"
              flex="1"
              align="center"
              justifyContent="center"
            >
              <Stack spacing="8">
                <VStack spacing="6">
                  <ChakraLogo textAlign="center" mb="10" h="72px" />
                  <Heading size={{ md: 'lg', xl: 'xl' }} textAlign="center">
                    All-In-One Event and Registration Platform
                  </Heading>
                </VStack>
              </Stack>
            </Flex>
            <Flex
              justifyContent="space-between"
              align="center"
              h="24"
              fontSize="sm"
            >
              <HStack divider={<BsDot />} spacing="8">
                <a href={TERMS_OF_USE_URL} target="_blank" rel="noreferrer">
                  Terms of Use
                </a>
                <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
                  Support
                </a>
              </HStack>
              <Flex align="center" display={{ base: 'none', lg: 'flex' }}>
                Made with <FaHeart style={{ margin: '0 4px' }} /> in San Diego
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Flex flex="1" h="100vh" direction="column">
          <Center flex="1">
            <SignInForm />
          </Center>
          <Center
            h="24"
            textColor="subtle"
            fontSize="sm"
            display={{ base: 'flex', md: 'none' }}
          >
            <HStack divider={<BsDot />} spacing="8">
              <a href={TERMS_OF_USE_URL} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
              <a href={PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
                Support
              </a>
            </HStack>
          </Center>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignInPage;
