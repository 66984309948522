import { AutoMap } from '@automapper/classes';
import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class UpdateEventHostDto {
  @IsString()
  @AutoMap()
  name!: string;

  @IsString()
  @AutoMap()
  slug!: string;

  @IsBoolean()
  @AutoMap()
  published!: boolean;

  @IsOptional()
  @IsString()
  @AutoMap()
  coverImageUrl?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  profileImageUrl?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  location?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  summary?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  facebookUrl?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  instagramUrl?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  linkedInUrl?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  tiktokUrl?: string;

  @IsOptional()
  @IsString()
  @AutoMap()
  twitterUrl?: string;

  constructor();
  constructor(props?: UpdateEventHostDto) {
    Object.assign(this, props || {});
  }
}
