import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../context/authentication-context';
import { ReactNode, useEffect } from 'react';
import { logger } from '@bookabl/client/util';

const PUBLIC_PATHS = ['/login'];
const ADMIN_REGEX = [/^\/admin\/.+/];

interface RouteGuardProps {
  children: ReactNode;
}

export default function RouteGuard({ children }: RouteGuardProps): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, currentUser, navigateToHome } = useAuth();

  function checkAuthState(url: string) {
    if (!isAuthenticated && !PUBLIC_PATHS.includes(url)) {
      navigate(
        {
          pathname: '/login',
          search: `?${createSearchParams({
            returnUrl: url,
          })}`,
        },
        { replace: true }
      );
    } else if (isAuthenticated) {
      if ('/login' === url) {
        logger.debug(
          `Attempting to load an unauthenticated only route '${url}' while authenticated. Redirecting to users home.`
        );
        navigateToHome();
      } else if (
        ADMIN_REGEX.some((regex) => regex.test(url)) &&
        !currentUser?.hasAdminAccess
      ) {
        navigate('/home', { replace: true });
      }
    }
  }

  useEffect(() => {
    checkAuthState(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return children as JSX.Element;
}
