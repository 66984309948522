import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { defaultTheme } from '@bookabl/client/common-ui';
import App from './app/app';
import { AuthenticationProvider } from './app/context/authentication-context';
import { ApiClientConfig } from '@bookabl/client/api-client';
import { logger } from '@bookabl/client/util';
import RouteGuard from './app/guards/route.guard';
import { QueryClient, QueryClientProvider } from 'react-query';
import { environment } from './environments/environment';
import { PageLoadingProvider } from './app/context/page-loading-context';
import { Loader } from '@googlemaps/js-api-loader';

export const queryClient = new QueryClient();

ApiClientConfig.baseUrl = environment.apiBaseUrl;
logger.level = environment.logLevel;

function renderApp() {
  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <ChakraProvider theme={defaultTheme}>
              <PageLoadingProvider>
                <RouteGuard>
                  <App />
                </RouteGuard>
              </PageLoadingProvider>
            </ChakraProvider>
          </AuthenticationProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </StrictMode>
  );
}

const loader = new Loader({
  apiKey: environment.googleApiKey,
  libraries: ['places'],
});

loader.load().then(() => {
  renderApp();
});
