import { useQuery } from 'react-query';
import {
  IntegrationClient,
  StripeIntegrationDtoPage,
} from './integration-client';
import { StripeIntegrationDto } from '@bookabl/shared/model/integration';

export function useGetStripeIntegrationsForOrganization(
  orgId: string,
  disabled?: boolean
) {
  return useQuery<StripeIntegrationDtoPage>(
    ['useGetStripeIntegrationsForOrganization', orgId],
    () => IntegrationClient.getStripeIntegrationsForOrganization(orgId),
    { enabled: !disabled }
  );
}

export function useGetStripeIntegrationsForProgram(
  orgId: string,
  programId: string,
  disabled?: boolean
) {
  return useQuery<StripeIntegrationDtoPage>(
    ['useGetStripeIntegrationsForProgram', orgId, programId],
    () => IntegrationClient.getStripeIntegrationsForProgram(orgId, programId),
    { enabled: !disabled }
  );
}

export function useFindStripeIntegrationById(
  orgId: string,
  programId: string | null,
  id: string,
  disabled?: boolean
) {
  if (programId) {
    return useQuery<StripeIntegrationDto>(
      ['useFindStripeIntegrationById', orgId, programId, id],
      () =>
        IntegrationClient.findStripeIntegrationByIdForProgram(
          orgId,
          programId,
          id
        ),
      { enabled: !disabled }
    );
  }

  return useQuery<StripeIntegrationDto>(
    ['useFindStripeIntegrationById', orgId, id],
    () => IntegrationClient.findStripeIntegrationById(orgId, id),
    { enabled: !disabled }
  );
}
