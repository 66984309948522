import {
  Avatar,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as Logo } from '../../../../assets/logos/bookabl_event_logo_dark_png.svg';
import AdminLayoutHeaderToggleButton from './admin-layout-header-toggle-button';
import { OrganizationDto } from '@bookabl/shared/model/organization';
import { CurrentUserState } from '../../../context/authentication-context';
import AdminSidebar from '../navigation/admin-sidebar/admin-sidebar';

export interface AdminLayoutHeaderProps {
  currentOrganization: OrganizationDto;
  currentUser?: CurrentUserState;
}

export function AdminLayoutHeader({
  currentOrganization,
  currentUser,
}: AdminLayoutHeaderProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Flex
      as="header"
      bg="blue.400"
      px="5"
      py="2"
      justify="space-between"
      align="center"
    >
      <Icon as={Logo} w="160px" h="56px" display="block" />
      {isDesktop ? (
        <Avatar
          bg="blue.600"
          color="white"
          size="md"
          name={currentUser?.name}
        />
      ) : (
        <>
          <AdminLayoutHeaderToggleButton
            isOpen={isOpen}
            aria-label="Open Menu"
            onClick={onToggle}
          />
          <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement="left"
            isFullHeight
            preserveScrollBarGap
          >
            <DrawerOverlay />
            <DrawerContent>
              <AdminSidebar
                currentOrganization={currentOrganization}
                scrollable
              />
            </DrawerContent>
          </Drawer>
        </>
      )}
    </Flex>
  );
}

export default AdminLayoutHeader;
