import { useGetStripeIntegrationsForOrganization } from '@bookabl/client/api-client';
import useAdminSession from '../../../context/admin-session-context';
import StripeIntegrationPage from '../../integration/pages/stripe-integration-page';

export function OrganizationStripeIntegrationPage() {
  const { currentOrganization } = useAdminSession();
  const { data: integrations } = useGetStripeIntegrationsForOrganization(
    currentOrganization.id
  );
  return (
    <StripeIntegrationPage integrations={integrations} context="ORGANIZATION" />
  );
}

export default StripeIntegrationPage;
