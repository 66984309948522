import { useGetStripeIntegrationsForProgram } from '@bookabl/client/api-client';
import StripeIntegrationPage from '../../integration/pages/stripe-integration-page';
import useCurrentProgram from '../../../context/current-program-context';

export function ProgramStripeIntegrationPage() {
  const { currentProgram } = useCurrentProgram();
  const { data: integrations } = useGetStripeIntegrationsForProgram(
    currentProgram.orgId,
    currentProgram.id
  );
  return (
    <StripeIntegrationPage integrations={integrations} context="PROGRAM" />
  );
}

export default ProgramStripeIntegrationPage;
