import { AutoMap } from '@automapper/classes';
import { IsNumber, IsString } from 'class-validator';

export class AuthenticationResponseDto {
  @IsString()
  @AutoMap()
  userId!: string;

  @IsString()
  @AutoMap()
  email!: string;

  @IsString()
  @AutoMap()
  idToken!: string;

  @IsString()
  @AutoMap()
  refreshToken!: string;

  @IsNumber()
  @AutoMap()
  expiresIn!: number;

  constructor(props?: AuthenticationResponseDto) {
    if (!props) return;
    this.email = props.email;
    this.idToken = props.idToken;
    this.refreshToken = props.refreshToken;
    this.expiresIn = props.expiresIn;
    this.userId = props.userId;
  }
}
