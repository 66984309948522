import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Image as ChakraImage } from '@chakra-ui/image';
import { useState } from 'react';
import AddLogoModal from '../add-logo-modal/add-logo-modal';
import { CreateProgramDto } from '@bookabl/shared/model/program';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MediaClient, ProgramClient } from '@bookabl/client/api-client';
import { CroppedRectangleDto } from '@bookabl/shared/model/integration';
import { useNavigate } from 'react-router-dom';

interface SelectedImage {
  imageFile: File;
  croppedImageDataUrl: string;
  croppedRectangle: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

/* eslint-disable-next-line */
export interface CreateProgramFormProps {
  orgId: string;
}

export function CreateProgramForm({ orgId }: CreateProgramFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<CreateProgramDto>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [selectedImage, setSelectedImage] = useState<SelectedImage>();
  const navigate = useNavigate();
  const onImageSelected = (imageSelected: SelectedImage) => {
    setLogoUrl(imageSelected.croppedImageDataUrl);
    setSelectedImage(imageSelected);
  };

  const onSubmit: SubmitHandler<CreateProgramDto> = async (dto) => {
    const program = await ProgramClient.createProgram({
      ...dto,
      orgId,
    });

    if (selectedImage) {
      let croppedRectangle: CroppedRectangleDto;
      if (selectedImage.croppedRectangle) {
        croppedRectangle = {
          ...selectedImage.croppedRectangle,
          finalHeight: 96,
          finalWidth: 96,
        };
      }

      const key = `/orgs/${program.orgId}/programs/${program.id}/media/logos`;

      await MediaClient.generateSignature({ key }).then(({ signature }) =>
        MediaClient.upload(
          selectedImage.imageFile,
          signature,
          croppedRectangle
        ).then((uploadRes) =>
          ProgramClient.setProgramMedia(program.id, uploadRes.path)
        )
      );

      navigate('..', { replace: true });
    }
  };

  return (
    <Box height="full" width="500px">
      <chakra.form height="full" onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" justifyContent="space-between" height="full">
          <Box>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">Program Name</FormLabel>
              <Input
                id="name"
                type="text"
                {...register('name', {
                  required: 'Field is required',
                })}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.description} mt="5">
              <FormLabel htmlFor="description">Description</FormLabel>
              <Textarea
                id="description"
                {...register('description', {
                  maxLength: {
                    value: 500,
                    message: 'Must be less than 500 characters',
                  },
                })}
              />
              <FormErrorMessage>
                {errors.description && errors.description.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.publicId} mt="5">
              <FormLabel htmlFor="url">URL</FormLabel>
              <InputGroup>
                <InputLeftAddon children="https://pva.mybookabl.com/" />
                <Input
                  id="url"
                  {...register('publicId', {
                    required: 'URL is required',
                    minLength: { value: 3, message: 'Minimum 3 characters' },
                    maxLength: { value: 36, message: 'Maximum 36 characters' },
                    pattern: {
                      value: /^[a-zA-Z0-9][a-zA-Z0-9_-]{2,35}$/,
                      message:
                        'Must start with a letter or number and only contain letter, numbers, underscores or dashes',
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.publicId && errors.publicId.message}
              </FormErrorMessage>
            </FormControl>
            <Box mt="5">
              <FormLabel>Logo/Avatar</FormLabel>
              <Flex alignItems="center">
                {logoUrl ? (
                  <ChakraImage src={logoUrl} boxSize="96px" />
                ) : (
                  <Flex
                    w="96px"
                    h="96px"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="gray.200"
                    fontSize="xs"
                  >
                    96 x 96
                  </Flex>
                )}
                <AddLogoModal onImageSelected={onImageSelected} />
              </Flex>
              <Text fontSize="sm" mt="2">
                Choose a logo or image for your program.
              </Text>
            </Box>
          </Box>
          <Box>
            <Button
              isLoading={isSubmitting}
              type="submit"
              size="lg"
              colorScheme="blue"
            >
              Create Program
            </Button>
          </Box>
        </Flex>
      </chakra.form>
    </Box>
  );
}

export default CreateProgramForm;
