import { IsNotEmpty, IsString } from 'class-validator';
import { AutoMap } from '@automapper/classes';

export class CredentialDto {
  @IsString()
  @IsNotEmpty()
  @AutoMap()
  email!: string;

  @IsString()
  @IsNotEmpty()
  @AutoMap()
  password!: string;

  constructor(props?: CredentialDto) {
    if (!props) return;
    this.email = props.email;
    this.password = props.password;
  }
}
