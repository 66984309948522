import { AutoMap } from '@automapper/classes';
import { UserDto } from '@bookabl/shared/model/common';
import { UserAccessScopeDto } from './user-access-scope.dto';

export class CurrentUserDto {
  @AutoMap({ typeFn: () => UserDto })
  user!: UserDto;

  @AutoMap({ typeFn: () => String })
  accessScopes!: UserAccessScopeDto[];

  constructor(props?: CurrentUserDto) {
    if (!props) return;
    Object.assign(this, props);
  }
}
