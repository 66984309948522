import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { FiEdit, FiPlus, FiTrash2 } from 'react-icons/fi';
import {
  OrganizationClient,
  useGetOrganizationUsers,
  useGetOrganizationUsersQueryKey,
} from '@bookabl/client/api-client';
import {
  OrganizationRoleDto,
  OrganizationUserDto,
  toOrganizationRoleDtoLabel,
} from '@bookabl/shared/model/organization';
import { Link } from 'react-router-dom';
import { organizationRoute } from '../../../utils/routing-utils';
import { logger } from '@bookabl/client/util';
import { useQueryClient } from 'react-query';
import { useToast } from '../../../hooks/use-toast';
import useAdminSession from '../../../context/admin-session-context';

interface ConfirmationModalProps {
  orgUser: OrganizationUserDto;
  orgId: string;
  isOwner: boolean;
}

function ConfirmationModal({
  orgUser,
  orgId,
  isOwner,
}: ConfirmationModalProps) {
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useBoolean();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();

  const confirmDelete = async (id: string) => {
    setIsDeleting.on();
    try {
      await OrganizationClient.deleteOrganizationUser(orgId, id);
      await queryClient.invalidateQueries(useGetOrganizationUsersQueryKey);
    } catch (err) {
      toast({
        status: 'error',
        description: 'There was an error removing the team member',
      });
      logger.error(`Error removing team member`, err);
    } finally {
      setIsDeleting.off();
      onClose();
    }
  };
  return (
    <>
      <Tooltip
        label="You cannot delete the owner."
        placement="auto"
        hasArrow
        shouldWrapChildren
        isDisabled={!isOwner}
      >
        <IconButton
          aria-label="Delete team member"
          icon={<FiTrash2 />}
          variant="ghost"
          color="error"
          disabled={isOwner}
          onClick={onOpen}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove {orgUser.user.name} from your team?
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="ghost" onClick={onClose} isDisabled={isDeleting}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                mr={3}
                onClick={() => confirmDelete(orgUser.id)}
                isLoading={isDeleting}
              >
                Yes, remove
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export function OrganizationTeamPage() {
  const {
    currentOrganization: { id: orgId },
  } = useAdminSession();
  const { data: orgUsers } = useGetOrganizationUsers(orgId);

  return (
    <Flex flexDir="column" h="full">
      <Heading fontSize="4xl" fontWeight="normal" color="gray.600">
        Team
      </Heading>
      <Text>Invite other users to manage your organization.</Text>
      <Box
        flex="1"
        bg="bg-surface"
        p={6}
        mt={5}
        borderRadius={{ base: 'none', md: 'lg' }}
      >
        <Flex mb={5} justifyContent="end">
          <Button
            leftIcon={<FiPlus />}
            colorScheme="blue"
            as={Link}
            to={organizationRoute(orgId, '/team/new')}
            replace
          >
            Add user
          </Button>
        </Flex>
        <Box
          boxShadow={{ base: 'none', md: 'sm' }}
          borderRadius={{ base: 'none', md: 'lg' }}
        >
          <Stack spacing={5}>
            <Box px={{ base: '4', md: '6' }} pt={5}>
              <Stack>
                <Text fontSize="lg" fontWeight="medium">
                  Team members
                </Text>
              </Stack>
            </Box>
            <Box overflowX="auto">
              <Table>
                <Thead>
                  <Tr>
                    <Th>
                      <HStack spacing={1}>
                        <Text>Name</Text>
                      </HStack>
                    </Th>
                    <Th>Role</Th>
                    <Th>Status</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {orgUsers?.data.map((orgUser) => {
                    const isOwner =
                      orgUser.role === OrganizationRoleDto.OrgOwner;
                    return (
                      <Tr key={orgUser.user.id}>
                        <Td>
                          <HStack spacing={3}>
                            <Avatar name={orgUser.user.name} boxSize={10} />
                            <Box>
                              <Text fontWeight="medium">
                                {orgUser.user.name}
                              </Text>
                              <Text color="muted">{orgUser.user.email}</Text>
                            </Box>
                          </HStack>
                        </Td>
                        <Td>{toOrganizationRoleDtoLabel(orgUser.role)}</Td>
                        <Td>
                          <Badge colorScheme="green">Active</Badge>
                        </Td>
                        <Td display="flex" justifyContent="end">
                          <HStack>
                            <Tooltip
                              label="You cannot edit the owner."
                              placement="auto"
                              hasArrow
                              shouldWrapChildren
                              isDisabled={!isOwner}
                            >
                              {isOwner ? (
                                <IconButton
                                  aria-label="Edit team member"
                                  icon={<FiEdit />}
                                  variant="ghost"
                                  disabled={isOwner}
                                />
                              ) : (
                                <IconButton
                                  aria-label="Edit team member"
                                  icon={<FiEdit />}
                                  variant="ghost"
                                  disabled={isOwner}
                                  as={Link}
                                  to={organizationRoute(
                                    orgId,
                                    `/team/${orgUser.id}`
                                  )}
                                />
                              )}
                            </Tooltip>
                            <ConfirmationModal
                              orgUser={orgUser}
                              orgId={orgId}
                              isOwner={isOwner}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
            <Box px={{ base: '4', md: '6' }} pb={5}>
              <Stack>
                <Text fontSize="sm" color="muted">
                  Showing {orgUsers?.data.length} of {orgUsers?.data.length}{' '}
                  results
                </Text>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
}

export default OrganizationTeamPage;
