import { AccountType } from './account-type';
import {
  IsEnum,
  IsNotEmpty,
  IsNotEmptyObject,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { CreateUserDto } from '@bookabl/shared/model/common';
import { Type } from 'class-transformer';
import { CreateOrganizationDto } from '@bookabl/shared/model/organization';
import { AutoMap } from '@automapper/classes';

export class CreateAccountDto {
  @IsEnum(AccountType)
  @AutoMap({ typeFn: () => String })
  type!: AccountType;

  @Type(() => CreateUserDto)
  @IsNotEmptyObject()
  @ValidateNested()
  @AutoMap()
  user!: CreateUserDto;

  @Type(() => CreateOrganizationDto)
  @ValidateIf((o) => AccountType.ORGANIZATION === o.type)
  @IsNotEmptyObject()
  @IsNotEmpty()
  @ValidateNested()
  @AutoMap()
  organization?: CreateOrganizationDto;

  constructor(props?: CreateAccountDto) {
    if (!props) return;
    this.type = props.type;
    this.user = props.user;
    this.organization = props.organization;
  }
}
