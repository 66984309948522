import { AutoMap } from '@automapper/classes';
import { IsDate, IsString } from 'class-validator';
import { Type } from 'class-transformer';

export class CreateEventOccurrenceDto {
  @IsDate()
  @AutoMap()
  @Type(() => Date)
  startDateTime!: Date;

  @IsDate()
  @AutoMap()
  @Type(() => Date)
  endDateTime!: Date;

  @IsString()
  @AutoMap()
  timezone!: string;

  constructor();
  constructor(props?: CreateEventOccurrenceDto) {
    Object.assign(this, props || {});
  }
}
