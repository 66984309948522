import { ClientError } from '../model/client-error';

/**
 * Throws an error on an error response from fetch API
 * @param response response object
 */
export async function throwOnError(response: Response) {
  if (!response.ok) {
    throw new ClientError(await response.json());
  }

  return response;
}

/**
 * Post data to the specified url
 *
 * @param url url
 * @param body
 */
export function post(url: string, body?: BodyInit): Promise<Response> {
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  }).then(throwOnError);
}

/**
 * Delete resource at the specified url
 *
 * @param url
 * @param body
 */
export function del(url: string, body?: BodyInit): Promise<Response> {
  return fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  }).then(throwOnError);
}

/**
 * Get data from the specified url
 *
 * @param url url
 */
export function get(url: string): Promise<Response> {
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(throwOnError);
}
