import { Stack } from '@chakra-ui/react';
import NavButton from '../nav-button';
import {
  FiHelpCircle,
  FiHome,
  FiCalendar,
  FiUsers,
  FiLogOut,
} from 'react-icons/fi';
import { TbPodium } from 'react-icons/tb';
import { programRoute } from '../../../../utils/routing-utils';
import { OrganizationDto } from '@bookabl/shared/model/organization';
import useCurrentProgram from '../../../../context/current-program-context';
import { BiPlug } from 'react-icons/bi';
import useAuth from '../../../../context/authentication-context';
import { ReactNode } from 'react';
import { FaRegHandshake } from 'react-icons/fa';

export interface AdminProgramSidebarProps {
  programSelector: ReactNode;
  currentOrganization: OrganizationDto;
}

export function AdminProgramSidebar({
  programSelector,
  currentOrganization,
}: AdminProgramSidebarProps) {
  const { currentProgram } = useCurrentProgram();
  const { logout } = useAuth();
  return (
    <Stack spacing={6} w="full">
      {programSelector}
      <Stack justify="space-between" spacing="1" w="100%" h="full">
        <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
          <Stack spacing="1">
            <NavButton
              label="Dashboard"
              icon={FiHome}
              to={programRoute(currentOrganization.id, currentProgram.id)}
            />
            <NavButton
              label="Events"
              icon={FiCalendar}
              to={programRoute(
                currentOrganization.id,
                currentProgram.id,
                '/events'
              )}
            />
            <NavButton
              label="Hosts"
              icon={TbPodium}
              to={programRoute(
                currentOrganization.id,
                currentProgram.id,
                '/hosts'
              )}
            />
            <NavButton
              label="Sponsors"
              icon={FaRegHandshake}
              to={programRoute(
                currentOrganization.id,
                currentProgram.id,
                '/sponsors'
              )}
            />
            <NavButton
              label="Team"
              icon={FiUsers}
              to={programRoute(
                currentOrganization.id,
                currentProgram.id,
                '/team'
              )}
            />
            <NavButton
              label="Integrations"
              icon={BiPlug}
              to={programRoute(
                currentOrganization.id,
                currentProgram.id,
                '/integrations'
              )}
            />
          </Stack>
        </Stack>
        <Stack spacing={{ base: '5', sm: '6' }}>
          <Stack spacing="1">
            <NavButton label="Help" icon={FiHelpCircle} />
            <NavButton
              label="Sign Out"
              icon={FiLogOut}
              onClick={() => logout()}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AdminProgramSidebar;
