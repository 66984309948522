import { plainToClass } from 'class-transformer';
import { apiClient } from '../api-client';
import { CreateProgramDto, ProgramDto } from '@bookabl/shared/model/program';
import { EventDto } from '@bookabl/shared/model/event';

export const EventClient = {
  async getEventsForProgram(
    orgId: string,
    programId: string
  ): Promise<EventDto[]> {
    return apiClient
      .get<EventDto[]>(`/organizations/${orgId}/programs/${programId}/events`)
      .then((res) => plainToClass(EventDto, res.data));
  },

  async createProgram(dto: CreateProgramDto): Promise<ProgramDto> {
    return apiClient
      .post<ProgramDto>(`/programs`, dto)
      .then((res) => plainToClass(ProgramDto, res.data));
  },
};
