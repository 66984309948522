import { AutoMap } from '@automapper/classes';
import { IsString } from 'class-validator';

export class AuthorizeStripeDto {
  @IsString()
  @AutoMap()
  code!: string;

  constructor(props?: AuthorizeStripeDto) {
    Object.assign(this, props || {});
  }
}
