import { AutoMap } from '@automapper/classes';
import { IsString } from 'class-validator';
import { ClassToPlainISODateFormatNoMillisUTCTimezone } from '@bookabl/shared/util';
import { CreateProgramDto } from './create-program.dto';

export class ProgramDto extends CreateProgramDto {
  @AutoMap()
  @IsString()
  id!: string;

  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  createdAt!: Date;

  @AutoMap()
  @ClassToPlainISODateFormatNoMillisUTCTimezone
  updatedAt!: Date;

  constructor(props?: ProgramDto) {
    super(props);
    Object.assign(this, props || []);
  }
}

export const emptyProgram = (): ProgramDto =>
  new ProgramDto({
    id: '',
    name: '',
    orgId: '',
    publicId: '',
    createdAt: new Date(),
    updatedAt: new Date(),
  });
