import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useProgram } from '@bookabl/client/api-client';
import { emptyProgram, ProgramDto } from '@bookabl/shared/model/program';

interface CurrentProgramContextProps {
  currentProgram: ProgramDto;
}

const defaultProgram = emptyProgram();

const CurrentProgramContext = createContext<CurrentProgramContextProps>({
  currentProgram: defaultProgram,
} as CurrentProgramContextProps);

export function CurrentProgramProvider({ children }: { children: ReactNode }) {
  const { programId } = useParams() as { programId: string };
  const { data: program, isLoading } = useProgram(programId);

  const stateMemo = useMemo(
    () => ({ programId, currentProgram: program || defaultProgram }),
    [program, programId]
  );

  return (
    <CurrentProgramContext.Provider value={stateMemo}>
      {!isLoading && program && children}
    </CurrentProgramContext.Provider>
  );
}

export default function useCurrentProgram(): CurrentProgramContextProps {
  return useContext(CurrentProgramContext);
}
