import { AutoMap } from '@automapper/classes';
import { IsOptional, IsString, MaxLength } from 'class-validator';

export class CreateProgramDto {
  @AutoMap()
  @IsString()
  orgId!: string;

  @AutoMap()
  @IsString()
  name!: string;

  @AutoMap()
  @IsString()
  publicId!: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  @MaxLength(500)
  description?: string;

  @AutoMap()
  @IsOptional()
  @IsString()
  logoUrl?: string;

  constructor(props?: CreateProgramDto) {
    Object.assign(this, props || {});
  }
}
