import { apiClient } from '../api-client';
import { PageDto } from '@bookabl/shared/model/common';
import {
  AuthorizeStripeDto,
  CreateStripeIntegrationDto,
  StripeIntegrationDto,
  UpdateStripeIntegrationDto,
} from '@bookabl/shared/model/integration';
import { plainToClass, Type } from 'class-transformer';
import { AutoMap } from '@automapper/classes';

export class StripeIntegrationDtoPage extends PageDto<StripeIntegrationDto> {
  @AutoMap()
  @Type(() => StripeIntegrationDto)
  override data!: StripeIntegrationDto[];
}

export const IntegrationClient = {
  async getStripeIntegrationsForOrganization(orgId: string) {
    return apiClient
      .get<PageDto<StripeIntegrationDto>>(
        `/organizations/${orgId}/integrations/stripe`
      )
      .then((res) => plainToClass(StripeIntegrationDtoPage, res.data));
  },

  async getStripeIntegrationsForProgram(orgId: string, programId: string) {
    return apiClient
      .get<PageDto<StripeIntegrationDto>>(
        `/organizations/${orgId}/programs/${programId}/integrations/stripe`
      )
      .then((res) => plainToClass(StripeIntegrationDtoPage, res.data));
  },

  async findStripeIntegrationByIdForProgram(
    orgId: string,
    programId: string,
    id: string
  ): Promise<StripeIntegrationDto> {
    return apiClient
      .get<StripeIntegrationDto>(
        `/organizations/${orgId}/programs/${programId}/integrations/stripe/${id}`
      )
      .then((res) => plainToClass(StripeIntegrationDto, res.data));
  },

  async findStripeIntegrationById(
    orgId: string,
    id: string
  ): Promise<StripeIntegrationDto> {
    return apiClient
      .get<StripeIntegrationDto>(
        `/organizations/${orgId}/integrations/stripe/${id}`
      )
      .then((res) => plainToClass(StripeIntegrationDto, res.data));
  },

  async deleteStripeIntegrationForProgram(
    orgId: string,
    programId: string,
    id: string
  ): Promise<void> {
    return apiClient
      .delete<void>(
        `/organizations/${orgId}/programs/${programId}/integrations/stripe/${id}`
      )
      .then(() => {
        // empty
      });
  },

  async deleteStripeIntegration(orgId: string, id: string): Promise<void> {
    return apiClient
      .delete<void>(`/organizations/${orgId}/integrations/stripe/${id}`)
      .then(() => {
        // empty
      });
  },

  async createStripeIntegrationForOrganization(
    orgId: string,
    dto: CreateStripeIntegrationDto
  ): Promise<StripeIntegrationDto> {
    return apiClient
      .post<StripeIntegrationDto>(
        `/organizations/${orgId}/integrations/stripe`,
        dto
      )
      .then((res) => plainToClass(StripeIntegrationDto, res.data));
  },

  async createStripeIntegrationForProgram(
    orgId: string,
    programId: string,
    dto: CreateStripeIntegrationDto
  ): Promise<StripeIntegrationDto> {
    return apiClient
      .post<StripeIntegrationDto>(
        `/organizations/${orgId}/programs/${programId}/integrations/stripe`,
        dto
      )
      .then((res) => plainToClass(StripeIntegrationDto, res.data));
  },

  async updateStripeIntegrationForOrganization(
    orgId: string,
    id: string,
    dto: UpdateStripeIntegrationDto
  ): Promise<StripeIntegrationDto> {
    return apiClient
      .put<StripeIntegrationDto>(
        `/organizations/${orgId}/integrations/stripe/${id}`,
        dto
      )
      .then((res) => plainToClass(StripeIntegrationDto, res.data));
  },

  async updateStripeIntegrationForProgram(
    orgId: string,
    programId: string,
    id: string,
    dto: UpdateStripeIntegrationDto
  ): Promise<StripeIntegrationDto> {
    return apiClient
      .put<StripeIntegrationDto>(
        `/organizations/${orgId}/programs/${programId}/integrations/stripe/${id}`,
        dto
      )
      .then((res) => plainToClass(StripeIntegrationDto, res.data));
  },

  async authorizeStripeIntegrationForOrganization(
    orgId: string,
    id: string,
    dto: AuthorizeStripeDto
  ): Promise<void> {
    return apiClient
      .post<StripeIntegrationDto>(
        `/organizations/${orgId}/integrations/stripe/${id}/authorization`,
        dto
      )
      .then(() => {
        // do nothing
      });
  },

  async authorizeStripeIntegrationForProgram(
    orgId: string,
    programId: string,
    id: string,
    dto: AuthorizeStripeDto
  ): Promise<void> {
    return apiClient
      .post<StripeIntegrationDto>(
        `/organizations/${orgId}/programs/${programId}/integrations/stripe/${id}/authorization`,
        dto
      )
      .then(() => {
        // do nothing
      });
  },
};
