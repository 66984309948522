import {
  Button,
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackProps,
  Text,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ReactComponent as Logo } from '../../../../assets/logos/bookabl_logo_main_png.svg';
import { SubmitHandler, useForm } from 'react-hook-form';
import useAuth from '../../../context/authentication-context';
import { CredentialDto } from '@bookabl/shared/model/authentication';

const ChakraLogo = chakra(Logo);

interface LoginFormInput {
  email: string;
  password: string;
}

export function SignInForm(props: StackProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [showPassword, setShowPassword] = useBoolean(false);
  const { authenticate } = useAuth();
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormInput>();

  const onSubmit: SubmitHandler<LoginFormInput> = async (data) => {
    try {
      await authenticate(new CredentialDto(data));
    } catch (err) {
      setError('password', {
        type: 'manual',
        message: 'Incorrect email/phone and password combination',
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="8" {...props}>
        <Stack spacing="6">
          {isMobile && <ChakraLogo my="5" />}
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Heading size={{ base: 'xs', md: 'sm' }}>
              Login in to your account
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="muted">Don't have an account?</Text>
              <Button variant="link" colorScheme="blue">
                Sign up
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">Email or Phone</FormLabel>
              <Input
                id="email"
                type="text"
                {...register('email', { required: 'Field is required' })}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', { required: 'Field is required' })}
                />
                <InputRightElement w="4.5rem">
                  <Button
                    size="sm"
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => setShowPassword.toggle()}
                  >
                    {showPassword ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
          </Stack>
          <Stack spacing="4">
            <Button variant="primary" type="submit" isLoading={isSubmitting}>
              Sign in
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}

export default SignInForm;
