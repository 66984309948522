import { apiClient } from '../api-client';
import { PageDto } from '@bookabl/shared/model/common';
import { plainToClass, Type } from 'class-transformer';
import { AutoMap } from '@automapper/classes';
import { isUndefined } from '@bookabl/client/util';
import {
  CreateEventHostDto,
  EventHostDto,
  UpdateEventHostDto,
} from '@bookabl/shared/model/event';

export class EventHostDtoPage extends PageDto<EventHostDto> {
  @AutoMap()
  @Type(() => EventHostDto)
  override data!: EventHostDto[];
}

export const HostClient = {
  async createHostForProgram(
    orgId: string,
    programId: string,
    dto: CreateEventHostDto
  ): Promise<EventHostDto> {
    return apiClient
      .post<EventHostDto>(
        `/organizations/${orgId}/programs/${programId}/event-hosts`,
        dto
      )
      .then((res) => plainToClass(EventHostDto, res.data));
  },

  async updateHostForProgram(
    orgId: string,
    programId: string,
    id: string,
    dto: UpdateEventHostDto
  ): Promise<EventHostDto> {
    return apiClient
      .put<EventHostDto>(
        `/organizations/${orgId}/programs/${programId}/event-hosts/${id}`,
        dto
      )
      .then((res) => plainToClass(EventHostDto, res.data));
  },

  async getHostForProgram(
    orgId: string,
    programId: string,
    id: string
  ): Promise<EventHostDto> {
    return apiClient
      .get<EventHostDto>(
        `/organizations/${orgId}/programs/${programId}/event-hosts/${id}`
      )
      .then((res) => plainToClass(EventHostDto, res.data));
  },

  async getHostsForProgram(
    orgId: string,
    programId: string,
    published?: boolean
  ): Promise<PageDto<EventHostDto>> {
    let params = undefined;
    if (!isUndefined(published)) {
      params = {
        published,
      };
    }
    return apiClient
      .get<PageDto<EventHostDto>>(
        `/organizations/${orgId}/programs/${programId}/event-hosts`,
        {
          params,
        }
      )
      .then((res) => plainToClass(EventHostDtoPage, res.data));
  },

  async deleteHostForProgram(
    orgId: string,
    programId: string,
    id: string
  ): Promise<void> {
    return apiClient
      .delete<void>(
        `/organizations/${orgId}/programs/${programId}/event-hosts/${id}`
      )
      .then(() => {
        // empty
      });
  },

  async setHostMediaForProgram(
    orgId: string,
    programId: string,
    id: string,
    mediaUrl: string,
    mediaType: string
  ): Promise<EventHostDto> {
    return apiClient
      .post<EventHostDto>(
        `/organizations/${orgId}/programs/${programId}/event-hosts/${id}/media`,
        {
          mediaUrl,
          mediaType,
        }
      )
      .then((res) => plainToClass(EventHostDto, res.data));
  },
};
