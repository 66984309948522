import { Stack } from '@chakra-ui/react';
import NavButton from '../nav-button';
import { FiHelpCircle, FiHome, FiLogOut, FiUsers } from 'react-icons/fi';
import useAuth from '../../../../context/authentication-context';
import { organizationRoute } from '../../../../utils/routing-utils';
import { OrganizationDto } from '@bookabl/shared/model/organization';
import { BiPlug } from 'react-icons/bi';
import { ReactNode } from 'react';

export interface AdminOrganizationSidebarProps {
  programSelector: ReactNode;
  currentOrganization: OrganizationDto;
}

export function AdminOrganizationSidebar({
  programSelector,
  currentOrganization,
}: AdminOrganizationSidebarProps) {
  const { logout } = useAuth();
  return (
    <Stack spacing={6} w="full">
      {programSelector}
      <Stack justify="space-between" spacing="1" w="100%" h="full">
        <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
          <Stack spacing="1">
            <NavButton
              label="Dashboard"
              icon={FiHome}
              to={organizationRoute(currentOrganization.id)}
            />
            <NavButton
              label="Team"
              icon={FiUsers}
              to={organizationRoute(currentOrganization.id, '/team')}
              exactMatch={false}
            />
            <NavButton
              label="Integrations"
              icon={BiPlug}
              to={organizationRoute(currentOrganization.id, '/integrations')}
              exactMatch={false}
            />
          </Stack>
        </Stack>
        <Stack spacing={{ base: '5', sm: '6' }}>
          <Stack spacing="1">
            <NavButton label="Help" icon={FiHelpCircle} />
            <NavButton
              label="Sign Out"
              icon={FiLogOut}
              onClick={() => logout()}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AdminOrganizationSidebar;
