import { ProgramDto } from '@bookabl/shared/model/program';
import { useQuery } from 'react-query';
import { ProgramClient } from '../program-client';

export function useProgram(id: string, enabled = true) {
  return useQuery<ProgramDto>(
    ['program', id],
    () => ProgramClient.getProgram(id),
    { enabled: enabled }
  );
}
