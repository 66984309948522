import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import useCurrentProgram from '../../../../../context/current-program-context';
import { useGetStripeIntegrationsForProgram } from '@bookabl/client/api-client';
import { forwardRef, ReactNode, useEffect, useState } from 'react';
import {
  useController,
  useFieldArray,
  UseFieldArrayAppend,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiChevronDown, FiChevronUp, FiTrash } from 'react-icons/fi';
import { toCurrencyFormat } from '../../../../../utils/utils';

interface ButtonRadioButtonProps {
  children: ReactNode;
}

const ButtonRadioButton = forwardRef<unknown, ButtonRadioButtonProps>(
  ({ children, ...props }, ref) => {
    const { state, getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps({ ref });
    const checkbox = getCheckboxProps();

    return (
      <Box as="label" w="full">
        <input {...input} />
        <Button
          as="div"
          {...checkbox}
          cursor="pointer"
          colorScheme="gray"
          variant={state.isChecked ? 'solid' : 'outline'}
          w="full"
        >
          {children}
        </Button>
      </Box>
    );
  }
);

const PaymentTypeToggle = forwardRef<unknown, any>(
  ({ control, name, defaultValue }, ref) => {
    const { field } = useController({
      name,
      control,
      defaultValue,
    });

    const { getRootProps, getRadioProps } = useRadioGroup({ ...field });

    return (
      <HStack {...getRootProps()} spacing={3}>
        <ButtonRadioButton {...getRadioProps({ value: 'paid' })}>
          Paid
        </ButtonRadioButton>
        <ButtonRadioButton {...getRadioProps({ value: 'free' })}>
          Free
        </ButtonRadioButton>
        <ButtonRadioButton {...getRadioProps({ value: 'donation' })}>
          Donation
        </ButtonRadioButton>
      </HStack>
    );
  }
);

interface EventTicketModalForm {
  name: string;
  price?: number;
  ticketType?: string;
}

interface EventTicketModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  append: UseFieldArrayAppend<EventTicketModalForm>;
}

const ticketSchema = yup.object({
  name: yup.string().required('Name is required'),
  ticketType: yup.string().required('Ticket type is required'),
  price: yup
    .string()
    .optional()
    .trim()
    .matches(/^[0-9]+(\.[0-9]{2})?$/, 'Must be a price (ex. ##.##)'),
});

function EventTicketModal({ isOpen, onClose, append }: EventTicketModalProps) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<EventTicketModalForm>({
    resolver: yupResolver(ticketSchema),
    mode: 'onBlur',
    defaultValues: {
      ticketType: 'paid',
    },
  });
  const [pricePlaceholder, setPricePlaceholder] = useState('');
  const watchedTicketType = watch('ticketType');

  useEffect(() => {
    switch (watchedTicketType) {
      case 'paid':
        setValue('price', undefined);
        setPricePlaceholder('');
        break;
      case 'donation':
        setValue('price', 0);
        setPricePlaceholder('Donation');
        break;
      case 'free':
        setValue('price', 0);
        setPricePlaceholder('Free');
        break;
    }
  }, [watchedTicketType, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add ticket</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={5}>
            <PaymentTypeToggle name="ticketType" control={control} />
            <FormControl isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input {...register('name')} />
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errors.price}>
              <FormLabel>Price</FormLabel>
              <InputGroup>
                <InputLeftElement>$</InputLeftElement>
                {watchedTicketType !== 'paid' && (
                  <Input placeholder={pricePlaceholder} disabled />
                )}
                <Input
                  {...register('price')}
                  placeholder={pricePlaceholder}
                  disabled={watchedTicketType !== 'paid'}
                  type="number"
                  hidden={watchedTicketType !== 'paid'}
                />
              </InputGroup>
              <FormErrorMessage>{errors.price?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button variant="ghost">Cancel</Button>
            <Button
              colorScheme="blue"
              px={10}
              onClick={handleSubmit((data) => {
                append(data);
                reset();
                onClose();
              })}
            >
              Add
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function displayTicketPrice({
  ticketType,
  price,
}: {
  price: string | number;
  ticketType: string;
}) {
  switch (ticketType) {
    case 'free':
      return 'Free';
    case 'donation':
      return 'Donation';
    default:
      return toCurrencyFormat(price);
  }
}

interface EventPaymentSectionProps {
  isCreate: boolean;
  onNext: (data: any) => void;
}

export function EventPaymentSection({ onNext }: EventPaymentSectionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentProgram } = useCurrentProgram();
  const { data: payments } = useGetStripeIntegrationsForProgram(
    currentProgram.orgId,
    currentProgram.id
  );

  const { control, register, watch, setValue } = useForm<any>({
    defaultValues: {
      price: undefined,
      ticketType: 'paid',
    },
  });
  const { fields, append, remove, swap } = useFieldArray<any>({
    control,
    name: 'tickets',
  });

  return (
    <form>
      <Flex flexDir="column" h="full">
        <Flex flexDir="column" flex="1" h="full" justifyContent="space-between">
          <Stack direction="row" spacing={10}>
            <Stack spacing={10} w="container.md">
              <Box>
                <Text fontSize="3xl">Payments</Text>
                <Text mb={4}>Manage tickets for your event</Text>
                <Box rounded="lg" bg="bg-surface" p={5}>
                  <Flex justifyContent="end" mb={5}>
                    <Button size="sm" colorScheme="blue" onClick={onOpen}>
                      Add Ticket
                    </Button>
                    <EventTicketModal
                      isOpen={isOpen}
                      onOpen={onOpen}
                      onClose={onClose}
                      append={append}
                    />
                  </Flex>
                  {fields.length === 0 && (
                    <Box>
                      <Alert status="warning">
                        <AlertIcon />
                        <AlertDescription>No tickets added</AlertDescription>
                      </Alert>
                      <Text mt={4} fontSize="sm">
                        Tickets will be required to publish your event. You can
                        save as draft without adding tickets.
                      </Text>
                    </Box>
                  )}

                  {fields.map((field: any, index) => {
                    return (
                      <Box key={field.id} py={2}>
                        <HStack justifyContent="space-between">
                          <Text>{field.name}</Text>
                          <HStack>
                            <Text>{displayTicketPrice(field)}</Text>
                            <IconButton
                              aria-label={`Move ticket '${field.name}' up`}
                              variant="ghost"
                              icon={<FiChevronUp />}
                              visibility={index > 0 ? 'visible' : 'hidden'}
                              onClick={() => swap(index, index - 1)}
                            />
                            <IconButton
                              aria-label={`Move ticket '${field.name}' down`}
                              variant="ghost"
                              icon={<FiChevronDown />}
                              visibility={
                                index === fields.length - 1
                                  ? 'hidden'
                                  : 'visible'
                              }
                              onClick={() => swap(index, index + 1)}
                            />
                            <IconButton
                              aria-label={`Remove ticket '${field.name}'`}
                              variant="ghost"
                              color="error"
                              icon={<FiTrash />}
                              onClick={() => remove(index)}
                            />
                          </HStack>
                        </HStack>
                        {/*{JSON.stringify(field)}*/}
                      </Box>
                    );
                  })}
                  {/*
                <FormControl>
                  <FormLabel>Event name</FormLabel>
                  <Input />
                  <FormErrorMessage></FormErrorMessage>
                </FormControl>
                */}
                </Box>
              </Box>

              <Box>
                <Text fontSize="3xl">Accounts</Text>
                <Text mb={4}>
                  Choose the account payments should be routed to
                </Text>
                <Box rounded="lg" bg="bg-surface" p={5}>
                  <FormControl>
                    <FormLabel>Payment account</FormLabel>
                    <Select placeholder="Select a payment account">
                      {payments?.data.map((p) => (
                        <option key={`paymentAccount-${p.id}`}>
                          {p.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage></FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Stack>
            <Box>
              <Image src="/assets/images/astro1.svg" />
            </Box>
          </Stack>
          <Flex w="full" justifyContent="end" pt={10}>
            <HStack spacing={10}>
              <Button variant="ghost">Cancel</Button>
              <Button colorScheme="blue" w={160} onClick={onNext}>
                Next
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
}

export default EventPaymentSection;
