import { OrganizationRoleDto } from './organization-role.dto';
import { Type } from 'class-transformer';
import { AutoMap } from '@automapper/classes';
import { IsEmail, IsNotEmpty, IsString } from 'class-validator';

export class OrganizationUserDetailsDto {
  @AutoMap()
  @IsString()
  id!: string;

  @AutoMap()
  @IsString()
  name!: string;

  @AutoMap()
  @IsNotEmpty()
  @IsEmail()
  email!: string;
}

export class OrganizationUserDto {
  @AutoMap()
  id!: string;

  @AutoMap({ typeFn: () => String })
  role!: OrganizationRoleDto;

  @AutoMap({ typeFn: () => OrganizationUserDetailsDto })
  @Type(() => OrganizationUserDetailsDto)
  user!: OrganizationUserDetailsDto;
}
