import { CreateEventHostDto } from './create-event-host.dto';
import { AutoMap } from '@automapper/classes';
import { IsString } from 'class-validator';

export class EventHostDto extends CreateEventHostDto {
  @IsString()
  @AutoMap()
  id!: string;

  @IsString()
  @AutoMap()
  orgId!: string;

  @IsString()
  @AutoMap()
  programId!: string;

  constructor();
  constructor(props?: EventHostDto) {
    super();
    Object.assign(this, props || {});
  }
}
