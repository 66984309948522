import AdminPageLayout from '../../../components/shared/layouts/admin-page-layout';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { ReactComponent as StripeLogo } from '../../../../assets/logos/stripe_wordmark_white.svg';
import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

export interface IntegrationCardProps {
  name: string;
  description: string;
  logo: ReactNode;
  to: To;
}

function IntegrationCard({
  logo,
  name,
  description,
  to,
}: IntegrationCardProps) {
  return (
    <Link to={to}>
      <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
        {logo}
        <Box p="6">
          <Box fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
            {name}
          </Box>
          {description && (
            <Box fontSize="sm" lineHeight="tight" noOfLines={1}>
              {description}
            </Box>
          )}
        </Box>
      </Box>
    </Link>
  );
}

export function OrganizationIntegrationPage() {
  return (
    <AdminPageLayout
      title="Integrations"
      description="Setup and manage integrations at the organization level that can be used across all of your programs."
    >
      <SimpleGrid
        columns={{ base: 1, sm: 2, md: 3 }}
        spacing={{ base: 5, md: 10 }}
      >
        <IntegrationCard
          logo={
            <Box bgColor="#7069fe">
              <StripeLogo />
            </Box>
          }
          to="./stripe"
          name="Stripe"
          description="Credit card and Payment processor"
        />
      </SimpleGrid>
    </AdminPageLayout>
  );
}

export default OrganizationIntegrationPage;
