import { apiClient } from '../api-client';
import { PageDto } from '@bookabl/shared/model/common';
import {
  CreateSponsorDto,
  SponsorDto,
  UpdateSponsorDto,
} from '@bookabl/shared/model/content';
import { plainToClass, Type } from 'class-transformer';
import { AutoMap } from '@automapper/classes';
import { isUndefined } from '@bookabl/client/util';

export class SponsorDtoPage extends PageDto<SponsorDto> {
  @AutoMap()
  @Type(() => SponsorDto)
  override data!: SponsorDto[];
}

export const SponsorClient = {
  async createSponsorForProgram(
    orgId: string,
    programId: string,
    dto: CreateSponsorDto
  ) {
    return apiClient
      .post<SponsorDto>(
        `/organizations/${orgId}/programs/${programId}/sponsors`,
        dto
      )
      .then((res) => plainToClass(SponsorDto, res.data));
  },

  async updateSponsorForProgram(
    orgId: string,
    programId: string,
    id: string,
    dto: UpdateSponsorDto
  ) {
    return apiClient
      .put<SponsorDto>(
        `/organizations/${orgId}/programs/${programId}/sponsors/${id}`,
        dto
      )
      .then((res) => plainToClass(SponsorDto, res.data));
  },

  async getSponsorForProgram(
    orgId: string,
    programId: string,
    id: string
  ): Promise<SponsorDto> {
    return apiClient
      .get<SponsorDto>(
        `/organizations/${orgId}/programs/${programId}/sponsors/${id}`
      )
      .then((res) => plainToClass(SponsorDto, res.data));
  },

  async setSponsorMediaForProgram(
    orgId: string,
    programId: string,
    id: string,
    mediaUrl: string
  ): Promise<SponsorDto> {
    return apiClient
      .post<SponsorDto>(
        `/organizations/${orgId}/programs/${programId}/sponsors/${id}/media`,
        {
          mediaUrl,
        }
      )
      .then((res) => plainToClass(SponsorDto, res.data));
  },

  async getSponsorsForProgram(
    orgId: string,
    programId: string,
    published?: boolean
  ): Promise<PageDto<SponsorDto>> {
    let params = undefined;
    if (!isUndefined(published)) {
      params = {
        published,
      };
    }
    return apiClient
      .get<PageDto<SponsorDto>>(
        `/organizations/${orgId}/programs/${programId}/sponsors`,
        {
          params,
        }
      )
      .then((res) => plainToClass(SponsorDtoPage, res.data));
  },

  async deleteSponsorForOrganization() {
    throw new Error('Not yet implemented!');
  },

  async deleteSponsorForProgram(orgId: string, programId: string, id: string) {
    return apiClient
      .delete<void>(
        `/organizations/${orgId}/programs/${programId}/sponsors/${id}`
      )
      .then(() => {
        // empty
      });
  },
};
