// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { LogLevel } from 'consola';

export const environment = {
  production: false,
  appBaseUrl: 'https://dev-ng-app.bookabl.io',
  apiBaseUrl: 'https://dev-ng-api.bookabl.io',
  logLevel: LogLevel.Warn,
  googleApiKey: 'AIzaSyAiIUfN29rdnhcN-fr5WDaE-qGxv0vKHaE',
  publicBucket: 'bookabl-platform-dev_public',
  stripeOauthUrl: 'https://connect.stripe.com/oauth/authorize',
  stripeClientId: 'ca_HIn5OTC7JM14vIF04g5caKH7iHNsOZ1M',
  stripePublishableKey: 'pk_test_XHH6hK3dp0idVO6FJTc01ZjS001VFtR7J',
};
