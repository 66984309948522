import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactNode, useEffect } from 'react';
import { usePageLoading } from '../../../context/page-loading-context';

export interface AdminPageLayoutProps {
  title?: string;
  description?: string;
  children: ReactNode;
  isLoading?: boolean;
  headerContent?: ReactNode;
}

export function AdminPageLayout({
  title,
  description,
  children,
  isLoading = false,
  headerContent,
}: AdminPageLayoutProps) {
  const { isLoading: contextIsLoading, setLoading } = usePageLoading();

  useEffect(() => {
    setLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (contextIsLoading) {
    return <div />;
  }

  return (
    <Flex flexDir="column" h="full">
      {title && (
        <Text
          lineHeight={2}
          fontSize="3xl"
          fontWeight="normal"
          color="gray.600"
          letterSpacing="tight"
        >
          {title}
        </Text>
      )}
      {description && <Text>{description}</Text>}
      {headerContent}
      <Flex flexDir="column" flex="1" mt={5}>
        <Box
          flex="1"
          bg="bg-surface"
          p={6}
          borderRadius={{ base: 'none', md: 'lg' }}
          color="gray.700"
        >
          {children}
        </Box>
      </Flex>
    </Flex>
  );
}

export default AdminPageLayout;
