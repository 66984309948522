import { environment } from '../../environments/environment';

export function imageUrl(url?: string): string | undefined {
  if (!url) {
    return url;
  }
  return `https://ik.imagekit.io/bookabl/ng/${environment.publicBucket}/${url}`;
}

export async function urlToFile(
  url: string,
  filename: string,
  mimeType: string
) {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
}

const usdCurrencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function toCurrencyFormat(value: number | string): string {
  let v;
  if (typeof value === 'string') {
    v = Number.parseFloat(value);
    if (Number.isNaN(v)) {
      return '';
    }
  } else {
    v = value;
  }

  return usdCurrencyFormat.format(v);
}
