import { useNavigate, useSearchParams } from 'react-router-dom';
import { organizationRoute, programRoute } from '../../../utils/routing-utils';
import { useEffect } from 'react';
import useAdminSession from '../../../context/admin-session-context';

export function InstallIntegrationRouterPage() {
  const { currentOrganization } = useAdminSession();
  const [searchParams] = useSearchParams({
    state: `o~${currentOrganization.id}`,
  });
  const state = searchParams.get('state') || '';
  const code = searchParams.get('code') || '';
  const stateParts = state.split(',');

  let orgId: string | undefined;
  let programId: string | undefined;
  let integrationId: string | undefined;
  for (let i = 0; i < stateParts.length; i++) {
    const fieldValue = stateParts[i].trim().split('~');
    if (fieldValue.length > 1) {
      const field = fieldValue[0];
      const value = fieldValue[1];
      switch (field) {
        case 'o':
          orgId = value;
          break;
        case 'p':
          programId = value;
          break;
        case 'i':
          integrationId = value;
          break;
      }
    }
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (!orgId) {
      navigate(organizationRoute(currentOrganization.id));
      return;
    }

    if (!integrationId) {
      navigate(organizationRoute(orgId));
      return;
    }

    let integrationPath = organizationRoute(
      orgId,
      `/integrations/stripe/${integrationId}`
    );
    if (programId) {
      integrationPath = programRoute(
        orgId,
        programId,
        `/integrations/stripe/${integrationId}`
      );
    }

    navigate(
      {
        pathname: integrationPath,
      },
      {
        replace: true,
        state: {
          action: 'install',
          code,
        },
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h1>Welcome to InstallIntegrationRouterPage!</h1>
    </div>
  );
}

export default InstallIntegrationRouterPage;
